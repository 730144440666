import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const BlogItem = ({ data }) => {
    return (
        <div className="blog">
            <div className="thumbnail">
                {
                    data?.attributes?.image?.data?.attributes?.url ?
                        <Link to={ `/blog-details/${data?.id}`} className="image"><img src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data?.attributes?.image?.data?.attributes?.url}`} alt="Blog" /></Link>
                        :
                        <Link to={ `/blog-details/${data?.id}`} className="image"><img src="/images/placeholder/expect-soon.png" alt="Blog" /></Link>
                }
            </div>
            <div className="info">
                <ul className="meta">
                {data?.attributes?.publishedAt && (
                        <li><i className="far fa-calendar"></i>{(new Date(data.attributes.publishedAt)).toLocaleString()}</li>
                    )}
                </ul>
                <h3 className="title"><Link to={ `/blog-details/${data?.id}`}>{data.attributes?.title}</Link></h3>
                <Link to={ `/blog-details/${data?.id}`} className="link"> <mark>Read More</mark> </Link>
            </div>
        </div>
    )
}

BlogItem.propTypes = {
    data: PropTypes.object
};

export default BlogItem
