import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { BlogsCategoriesEndpoint } from "../../APIs";
import { fetchGlobal } from '../../utils/apis';

const SidebarCategories = () => {
    const [categories, setCategories] = useState([])
    useEffect(() => {
        const getCategories = async () => {
            const response = await fetchGlobal(BlogsCategoriesEndpoint)
            try {
                !response.hasError && setCategories(response.data.data);
            } catch (err) {
                setCategories([]);
            }
        }
        getCategories()
      }, [])
    return (
        <div className="sidebar-widget-content">
            <ul className="sidebar-widget-cate-list">
                {categories.map(cat => {
                    return (
                        <li key={cat.attributes.slug}>
                            <Link to={ `/category/${cat.attributes?.slug}`}>
                                <span className="text">{cat.attributes?.title}</span> 
                                <span className="count">{cat.attributes?.blogs?.data?.length}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}



export default SidebarCategories;
