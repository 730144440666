import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import GoogleMap from '../../container/Map/GoogleMap';
import ContactPerson from '../ContactInformation/ContactPerson';
import { ListingsEndpoint } from '../../APIs';
import { fetchGlobal } from '../../utils/apis';

SwiperCore.use([Navigation, Autoplay]);

const NowSellingDetailsContainer = ({ data }) => {
  const [listings, setListings] = useState([]);
  useEffect(() => {
    const getListings = async () => {
      const response = await fetchGlobal(ListingsEndpoint);
      !response.hasError && setListings(response.data.data);
    };
    getListings();
  }, []);

  const sliceText = (text) => {
    return text.slice(0, 60);
  };

  const swiperOption = {
    centeredSlides: true,
    loop: true,
    speed: 750,
    spaceBetween: 10,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.intro-slider .swiper-button-next',
      prevEl: '.intro-slider .swiper-button-prev',
    },
    modules: [Autoplay],
  };

  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-12 pb-2">
            <div className="work-left work-details pb-2" data-aos="fade-up">
              <div className="portfolio-main-info pb-5">
                <div className="row ">
                  <div className="col-lg-8  mb-3">
                    <h2 className="title pb-3">
                      {' '}
                      {data.attributes?.propertyName}{' '}
                    </h2>
                    <div className="mb-5">
                      <span>Published:</span>
                      <span>
                        {new Date(
                          data.attributes?.publishedAt
                        ).toLocaleDateString('en-US')}
                      </span>
                    </div>
                    <div className="imageSlides my-5">
                      <Swiper
                        effect="fade"
                        className="intro-slider mt-5"
                        {...swiperOption}
                      >
                        {data?.attributes?.propertyPictures?.data &&
                          data?.attributes?.propertyPictures?.data?.map(
                            (val, index) => (
                              <SwiperSlide key={val}>
                                <img
                                  src={val.attributes.url}
                                  alt={data.attributes?.propertyName}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                  }}
                                />
                              </SwiperSlide>
                            )
                          )}

                        <div
                          className="home-slider-next main-slider-nav swiper-button-next bg-primary text-white"
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <i className="fal fa-angle-right"></i>
                        </div>
                        <div
                          className="home-slider-prev main-slider-nav swiper-button-prev bg-primary text-white"
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <i className="fal fa-angle-left"></i>
                        </div>
                      </Swiper>
                    </div>
                    <div className="work-left work-details mt-6">
                      <div className="work-main-info">
                        <div className="work-content">
                          <div className="desc mt-8">
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw]}
                              children={data.attributes?.information}
                            />

                          </div>
                        </div>
                      </div>
                    </div>

                      <GoogleMap location={data.attributes?.location}
                       
                      />
                  
                  </div>
                  <div className="col-lg-4">
                    <h3 className="title fz-24 mb-6 text-center">
                      Also Check{' '}
                    </h3>
                    <ul className="list-group">
                      {listings.map((single, key) => {
                        return data.attributes?.propertyName !== single.attributes?.propertyName && (
                          <li key={key} className="list-group-item ">
                            <a href={`/now-selling-details/${single.id}`}>
                              {sliceText(single.attributes?.propertyName)}....
                              {/* {single.attributes?.propertyName} */}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mb-9">
            <div className="col-12 my-5">
              {data.attributes?.contact_people?.data.length > 0 && (
                <h2 className="title text-left pb-5" data-aos="fade-up">
                  Contact Persons
                </h2>
              )}
              <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                {data.attributes?.contact_people?.data &&
                  data.attributes?.contact_people?.data.map((person) => (
                    <ContactPerson person={person?.attributes || {}} />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-12 my-5">
            <h2 className="title pb-5 text-left" data-aos="fade-up">
              Online Payment Details
            </h2>

            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 d-flex justify-content-left align-items-centre">
              <div
                className="col mb-6 aos-init aos-animate text-center"
                data-aos="fade-up"
              >
                <div className="bg-white shadow border px-6 py-4">
                  <div className="icon"></div>
                  <div className="info">
                    <h4 className="title">
                      Use the account details below to make payment{' '}
                    </h4>
                    <br />
                    <span className="info-text">
                      Name :Delta Trust Mortgage Bank <br />
                    </span>
                    <span className="info-text">Account: 40110881533</span> <br />
                    <span className="info-text">Bank: Fidelity</span> <br />
                    <hr></hr>
                    <br />
                    <span className="info-text">
                      Name :Delta Trust Mortgage Bank <br />
                    </span>
                    <span className="info-text">Account: 0045575029</span> <br />
                    <span className="info-text">Bank: Union </span> <br />

                    <div className="py-4">
                      <a
                        href="https://www.quickteller.com/deltatrust"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary flex text-white text-decoration-none"
                      >
                        Pay {data.attributes?.price} Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

NowSellingDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default NowSellingDetailsContainer;
