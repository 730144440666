import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AOS from 'aos'
import NavScrollTop from './components/NavScrollTop'
import HomePage from './pages/HomePage'
import About from './pages/About'
import Service from './pages/Service'
import ServiceDetails from './pages/ServiceDetails'
import Work from './pages/Work'
import WorkDetails from './pages/WorkDetails'
import BlogGrid from './pages/BlogGrid'
import BlogClassic from './pages/BlogClassic'
import BlogDetails from './pages/BlogDetails'
import BlogCategories from './pages/BlogCategories'
import BlogTag from './pages/BlogTag'
import Contact from './pages/Contact'
import SalesContact from './pages/SalesContact'
import NowSellingDetails from './pages/NowSellingDetails'
import NotFound from './pages/NotFound'
import ChatComponent from './components/LiveChat/ChatComponent'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import TermsPage from './pages/Terms'
import UploadDocsForm from './pages/UploadDocsForm'

// CSS File Here
import 'aos/dist/aos.css'
import 'react-modal-video/scss/modal-video.scss'
import './assets/scss/style.scss'
import NowSelling from './pages/NowSelling'
import FinancedProjects from './pages/FinancedProjects'
import FinancedProjectsDetails from './pages/FinancedProjectsDetails'
import OpenForm from './pages/OpeningForm'
import AskOpenForm from './pages/AskOpeningForm'
import PhotoGallery from './pages/PhotoGallery'
import TeamDetails from './pages/TeamDetails'
import UploadForm from './components/UploadDocs'
import ApiClient from './pages/ApiClient'

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: 'ease',
    })
    AOS.refresh()
  }, [])
  return (
    <Router>
      <NavScrollTop>
        <ChatComponent />
        <Switch>
          <Route path={`${'/'}`} exact component={HomePage} />
          <Route path={`${'/about'}`} component={About} />
          <Route path={`${'/service'}`} component={Service} />
          <Route
            path={`${'/service-details/:id'}`}
            component={ServiceDetails}
          />
          <Route path={`${'/NowSelling'}`} component={NowSelling} />
          <Route
            path={`${'/Now-Selling-Details/:id'}`}
            component={NowSellingDetails}
          />
          <Route
            path={`${'/financed-projects'}`}
            component={FinancedProjects}
          />
          <Route
            path={`${'/financed-projects-details/:id'}`}
            component={FinancedProjectsDetails}
          />
          <Route path={`${'/projects'}`} component={Work} />
          <Route path={`${'/work-details/:id'}`} component={WorkDetails} />
          <Route path={`${'/blog-grid'}`} component={BlogGrid} />
          <Route path={`${'/blog-classic'}`} component={BlogClassic} />
          <Route path={`${'/tag/:slug'}`} component={BlogTag} />
          <Route path={`${'/category/:slug'}`} component={BlogCategories} />
          <Route path={`${'/blog-details/:id'}`} component={BlogDetails} />
          <Route path={`${'/contact'}`} component={Contact} />
          <Route path={`${'/sales-contact'}`} component={SalesContact} />
          <Route path={`${'/opening-account-form'}`} component={OpenForm} />
          <Route
            path={`${'/ask-opening-account-form'}`}
            component={AskOpenForm}
          />
          <Route path={`${'/directors/:id'}`} component={TeamDetails} />
          <Route path={`${'/staff/:id'}`} component={TeamDetails} />
          <Route path={`${'/photogallery'}`} component={PhotoGallery} />
          <Route path={`${'/upload'}`} component={UploadForm} />
          <Route
            path={`${'/account-update-form'}`}
            component={UploadDocsForm}
          />
          <Route path='/terms-of-service' component={TermsPage} />
          <Route path='/apiclient' component={ApiClient} />

          <Route path='/privacy-policy' component={PrivacyPolicyPage} />

          <Route path={'*'} component={NotFound} />
        </Switch>
      </NavScrollTop>
    </Router>
  )
}

export default App
