import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import deletaTrustLogoColor from "../../assets/images/logo/footer-logo.png";

const Logo = () => {
    return(
        <div className="header-logo">
            <Link to="/">
                <img src={deletaTrustLogoColor} alt="logo" />
            </Link>
        </div>
    )
}

Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
