import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import WorkItemTwo from '../../components/Work/WorkItemTwo.jsx';
import { useEffect, useState } from 'react';
import { ListingsEndpoint } from '../../APIs';
import { fetchGlobal } from '../../utils/apis';

const FeaturedProperty = () => {
  const [listings, setListings] = useState([])
  useEffect(() => {
    const getListings = async () => {
      const response = await fetchGlobal(ListingsEndpoint)
      !response.hasError && setListings(response.data.data)
    }
    getListings()
  }, [])
  return (
    <div className="section section-padding-t90-b100">
      <div className="container">
        <SectionTitle
          headingOption="title fz-32"
          title="Featured Properties"
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
          {listings?.map((single, key) => {
            if (!single.attributes?.featured) {
              return <></>
            }
              return (
                <div
                  key={key}
                  className="col mb-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <WorkItemTwo
                    classOption="box-border"
                    data={single}
                    key={key}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProperty;
