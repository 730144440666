import PropTypes from "prop-types";
import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import ServiceIconBox from '../../components/IconBox/ServiceIconBox.jsx';
import {useEffect, useState} from "react";
import { fetchGlobal } from "../../utils/apis";
import { ServicesEndpoint } from "../../APIs";
import servicesIcons from "../../data/icons/services-icons.json";

const HomeServiceIconBox = ({ classOption }) => {
  const [services, setServices] = useState([])
  useEffect(() => {
    const getServices = async () => {
      const response = await fetchGlobal(ServicesEndpoint)
      !response.hasError && setServices(response.data.data)
    }
    getServices()
  }, [])
  return (
    <div className={`section mt-5 mb-5 ${classOption}`}>
      <div className="container">
        <SectionTitle
          title="What Makes Us the Best Choice for Your Mortgage Needs"
          subTitle="Experience, Expertise, and Exceptional Service"
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
        {services?.slice(0,6).map((single, key) => {
           return(
              <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                  <ServiceIconBox classOption="box-border" imageSVG={servicesIcons[Math.min(key, servicesIcons.length - 1)].icon} data={single} key={key} />
              </div>
          ); 
        })}
        </div>
      </div>
    </div>
  );
};

ServiceIconBox.propTypes = {
  classOption: PropTypes.string,
};
ServiceIconBox.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default HomeServiceIconBox;
