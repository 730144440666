import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const BlogClassic = ({ data }) => {
    return (
        <div className="blog-3 col">
            <div className="thumbnail">
                <Link to={`/blog-details/${data.id}`} className="image">
                    {
                        data.attributes?.image?.data?.attributes?.url ?
                            <img src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data.attributes?.image?.data?.attributes?.url}`} alt="Blog Image" />
                            :
                            <img src="/images/placeholder/expect-soon.png" alt="Blog Image" />
                    }
                </Link>
            </div>
            <div className="info">
                <ul className="meta">
                    {data?.attributes?.publishedAt && (
                        <li><i className="far fa-calendar"></i>{(new Date(data.attributes.publishedAt)).toLocaleString()}</li>
                    )}
                </ul>
                <h3 className="title"><Link to={ `/blog-details/${data.id}`}>{data?.attributes?.title}</Link></h3>
                <div className="desc">
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={data?.attributes?.content?.substr(1,500) + '...'}
                    />
                </div>
                <Link to={ `/blog-details/${data?.id}`} className="btn btn-primary btn-hover-secondary mt-6">Read More</Link>
            </div>
        </div>
    )
}
BlogClassic.propTypes = {
    data: PropTypes.object
};
export default BlogClassic;
