import React, { useState, useEffect } from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import { slugify } from "../utils";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import { BlogCategoryTitleEndpoint } from "../APIs";
import BlogCategoryContainer from "../container/BlogGrid/BlogCategoryContainer";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { fetchGlobal } from "../utils/apis";

const BlogCategories = ({
  match: {
    params: { slug },
  },
}) => {
  const [categoryTitle, setCategoryTitle] = useState("");

  useEffect(() => {
    const getCategoryTitle = async () => {
        const response = await fetchGlobal(BlogCategoryTitleEndpoint(slug));
        if (!response.hasError && response.data.data) {
            if (response.data.data.length > 0) {
                setCategoryTitle(response.data.data[0].attributes?.title);
            }
        }
    };
    getCategoryTitle();
  });

  if (!slug) {
    return;
  }

  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Blog" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-blog.png"
        title={categoryTitle}
        content="Home"
        contentTwo="Blogs & News"
      />
      <BlogCategoryContainer slug={slug} />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

BlogCategories.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

export default BlogCategories;
