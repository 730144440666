import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const ContactSalesFrom = () => {
  const { register, errors } = useForm({
    mode: 'onBlur',
  });

  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_SALES_ID || 'no-endpoint' );

  if (state.succeeded) {
    return <p className="text-center h3 text-primary">Your Data has been submitted <br/> Our Sales Team will contact you  </p>;
  }
  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form onSubmit={handleSubmit}>
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Your First Name *"
              name="firstname"
            />
            <ValidationError
              prefix="text"
              field="firstname"
              errors={state.errors}
            />
          </div>

          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Your Last Name *"
              name="lastname"
              // ref={register({ required: 'Last Name is required' })}
            />
            <ValidationError
              prefix="text"
              field="lastname"
              errors={state.errors}
            />
          </div>

          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Phone Number *"
              name="phone"
            />
            <ValidationError
              prefix="[0-9]{10}"
              field="phone"
              errors={state.errors}
            />
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="email"
              required
              placeholder="Email *"
              name="email"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>

          <div className="col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Street Address *"
              name="st_address"
            />
            <ValidationError
              prefix="text"
              field="st_address"
              errors={state.errors}
            />
          </div>

          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              required
              placeholder="City Address *"
              name="ct_address"
            />
            <ValidationError
              prefix="text"
              field="ct_address"
              errors={state.errors}
            />
          </div>

          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              required
              placeholder="State / Region *"
              name="dt_address"
            />
            <ValidationError
              prefix="text"
              field="dt_address"
              errors={state.errors}
            />
          </div>

          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message"
              required
            ></textarea>
            <ValidationError
              prefix="text"
              field="message"
              errors={state.errors}
            />
          </div>
          <div className="col-12 text-center mb-6">
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary w-100"
              disabled={state.submitting}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactSalesFrom;
