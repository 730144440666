import React from "react";
import Intro from "../../components/Intro/Intro.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { HomeCarousel } from "../../APIs";
import { fetchGlobal } from "../../utils/apis";
import { useEffect, useState } from "react";
import { Autoplay } from "swiper";

SwiperCore.use([Navigation, Autoplay]);

const IntroSlider = () => {
  const [carousel, setCarousel] = useState([]);
  useEffect(() => {
    const getCarousel = async () => {
      const response = await fetchGlobal(HomeCarousel);
      !response.hasError && setCarousel(response.data.data);
    };
    getCarousel();
  }, []);
  const swiperOption = {
    loop: true,
    speed: 750,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".intro-slider .swiper-button-next",
      prevEl: ".intro-slider .swiper-button-prev",
    },
  };
  return (
    <div className="intro-slider-wrap section">
      <Swiper effect="fade" className="intro-slider" {...swiperOption}>
        {carousel?.map((single, key) => {
          return (
            <SwiperSlide key={key}>
              <Intro data={single} key={key} />
            </SwiperSlide>
          );
        })}
        <div className="home-slider-next main-slider-nav swiper-button-next">
          <i className="fal fa-angle-right"></i>
        </div>
        <div className="home-slider-prev main-slider-nav swiper-button-prev">
          <i className="fal fa-angle-left"></i>
        </div>
      </Swiper>
    </div>
  );
};

export default IntroSlider;
