import React from 'react';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import NowSellingDetailsContainer from '../container/NowSelling/NowSellingDetailsContainer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { useEffect, useState } from 'react';
import { ListingEndpoint } from '../APIs';
import { fetchGlobal } from '../utils/apis';
import ContactSalesFromContainer from '../container/ContactFromContainer/ContactSalesFormContainer';

const WorkDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [listing, setListing] = useState([])
  useEffect(() => {
    const getListing = async () => {
      const response = await fetchGlobal(ListingEndpoint(id))
      !response.hasError && setListing(response.data.data)
    }
    getListing()
  }, [])
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Now Selling Details" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title={listing?.attributes?.propertyName}
        content="Home"
        contentTwo="Work"
      />
      <NowSellingDetailsContainer data={listing} />
      
      <ContactSalesFromContainer />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

WorkDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default WorkDetails;
