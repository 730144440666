import { useState, useEffect } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import { ValuesEndpoint } from "../../APIs";
import { fetchGlobal } from "../../utils/apis";

const AboutSix = () => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const getValues = async () => {
      const response = await fetchGlobal(ValuesEndpoint);
      !response.hasError && setValues(response.data.data);
    };
    getValues();
  }, []);

  return (
    <div
      id="core-values"
      className="section section-padding-top technology-section-padding-bottom-150"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12" data-aos="fade-up" data-aos-delay="300">
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle={values?.attributes?.subTitle}
                title={values?.attributes?.title}
              />
              <div className="row">
                {
                  values?.attributes?.points?.map((point) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                      <div className="about-content border p-4 shadow">
                        <h3 className="title d-flex text-primary">
                          <div className="icon">
                            <i className="fas fa-check"></i>
                          </div>
                          {point.title}
                        </h3>
                        <p>{point.desc}</p>
                      </div>
                    </div>
                ))}
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Link
        className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4"
        to="/ask-opening-account-form"
    >
       Secure Your Dream Property with Us Today!
    </Link>
</div>


          

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSix;
