import React, { useState } from 'react'

const ApiClient = () => {
  const [url, setUrl] = useState('')
  const [method, setMethod] = useState('GET')
  const [body, setBody] = useState('')
  const [response, setResponse] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setResponse('')
    setError('')
    setIsLoading(true)

    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
          method,
          body: method === 'POST' ? JSON.parse(body) : null,
        }),
      }

      const res = await fetch(
        `${process.env.REACT_APP_STATIC_IP_CNAME}/api/proxy-agent`,
        options
      )
      const data = await res.json()
      setResponse(JSON.stringify(data, null, 2))
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const containerStyle = {
    marginTop: '50px',
    maxWidth: '600px',
    margin: '0 auto',
  }

  const preStyle = {
    maxHeight: '500px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    backgroundColor: '#f8f9fa',
    padding: '1em',
    borderRadius: '5px',
  }

  const buttonStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '10px',
  }

  return (
    <div style={containerStyle}>
      <h1 className='mb-4'>API Client</h1>
      <form onSubmit={handleSubmit}>
        <div className='mb-3'>
          <label htmlFor='url' className='form-label'>
            URL:
          </label>
          <input
            type='text'
            className='form-control'
            id='url'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
            disabled={isLoading}
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='method' className='form-label'>
            Method:
          </label>
          <select
            className='form-select'
            id='method'
            value={method}
            onChange={(e) => setMethod(e.target.value)}
            disabled={isLoading}
          >
            <option value='GET'>GET</option>
            <option value='POST'>POST</option>
          </select>
        </div>
        {method === 'POST' && (
          <div className='mb-3'>
            <label htmlFor='body' className='form-label'>
              JSON Body:
            </label>
            <textarea
              className='form-control'
              id='body'
              rows='5'
              value={body}
              onChange={(e) => setBody(e.target.value)}
              disabled={isLoading}
              placeholder='Enter JSON data here...'
            ></textarea>
          </div>
        )}
        <button type='submit' className='btn btn-primary' disabled={isLoading}>
          {isLoading ? (
            <>
              <span
                className='spinner-border spinner-border-sm me-2'
                role='status'
                aria-hidden='true'
              ></span>
              Sending Request...
            </>
          ) : (
            'Send Request'
          )}
        </button>
      </form>
      {error && (
        <div className='alert alert-danger mt-3' role='alert'>
          <strong>Error:</strong> {error}
        </div>
      )}
      {response && (
        <div className='mt-3'>
          <h3>Response:</h3>
          <div style={{ position: 'relative' }}>
            <pre style={preStyle}>
              <code>{response}</code>
            </pre>
            <button
              className='btn btn-sm btn-secondary'
              style={buttonStyle}
              onClick={() => {
                navigator.clipboard.writeText(response)
              }}
              title='Copy to clipboard'
            >
              Copy
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ApiClient
