import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ContactInfoItem from "../../components/ContactInfo/ContactInfoItem.jsx";
import { fetchGlobal } from "../../utils/apis";
import { ContactEndpoint } from "../../APIs";

const Offices = ({ classOption }) => {
  const [contactInfo, setContactInfo] = useState([]);
  useEffect(() => {
    const getContactInfo = async () => {
      const response = await fetchGlobal(ContactEndpoint);
      !response.hasError &&
        setContactInfo(response.data.data.attributes.offices);
    };
    getContactInfo();
  }, []);

  return (
    <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
      {contactInfo?.map((single, key) => {
        return (
          <div key={key} className="col mb-6" data-aos="fade-up">
            <ContactInfoItem data={single} key={key} />
          </div>
        );
      })}
    </div>
  );
};

Offices.propTypes = {
  classOption: PropTypes.string,
};

export default Offices;
