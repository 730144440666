import PropTypes from "prop-types";

const ContactPerson = ({ person: { name, phone, email } }) => {
  return (
      <div key={name} className="col mb-6" data-aos="fade-up">
        <div className="contact-info">
          <div className="icon"></div>
          <div className="info">
            <h4 className="title">{name}</h4>
            <br />
            <span className="info-text">{phone}</span>
            <span className="info-text">{email}</span>
          </div>
        </div>
      </div>
  );
};

ContactPerson.propTypes = {
  classOption: PropTypes.string,
};

export default ContactPerson;
