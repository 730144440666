import React, { useState } from 'react';
// import { FaExclamationTriangle } from 'react-icons/fa';
// import { AiFillCheckCircle } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import CallToAction from '../container/CallToAction/CallToAction';
import OpeningFormComponent from '../components/OpeningFormComponent';

const OpenForm = () => {
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Opening Form" />
      <Header />
      <Breadcrumb
        image="images/bg/video-bg.jpg"
        title="Start Your Path to Homeownership Today with Your New Account!"
        content="Home"
        contentTwo="Open Account"
      />
      <OpeningFormComponent />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
}

export default OpenForm;
