import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import TermsofUseContainer from '../container/PolicyTerms/Terms.js';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const TermsPage = () => {
    return (
        <React.Fragment>
            <SEO title="DeltaTrust Terms of Use" />
            <Header />
            <Breadcrumb
        image="images/bg/bg-contact-.jpg"
        title="Delta Trust ensures fair and transparent terms for our products and services"
        content="Home"
        contentTwo="Terms of Use"
        />
            
            <TermsofUseContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default TermsPage