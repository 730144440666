import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import { useState, useEffect } from 'react';

import { fetchGlobal } from '../../utils/apis';
import { OurCorporateHistoryEndpoint } from '../../APIs';

const OurCorporateHistory = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    const getValues = async () => {
      const response = await fetchGlobal(OurCorporateHistoryEndpoint);
      !response.hasError && setContent(response.data.data?.attributes || {});
    };
    getValues();
  }, []);
  return (
    <div className="section section-padding-top about-section-padding-bottom-20">
      <div className="container">
        <div className="row">
          <div className="col-12" data-aos="fade-up">
            <div className=" mt-0 mb-md-10 mb-10">
              <SectionTitleTwo
                subTitle="Our Corporate History"
                title={content.title}
              />

              <div className="row ">
                <div
                  className="col-12 mt-2 mb-6 "
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  <p dangerouslySetInnerHTML={{__html: content.desc}} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OurCorporateHistory;
