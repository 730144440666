import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || 404" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-five.jpg"
        title="404"
        content="Home"
        contentTwo="404"
      />
      <div className="section section-padding-t90-b100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="error-content-wrap text-center">
                <div className="error-content">
                  <h1 className="text-primary">404</h1>
                  <h3>Page Not Found</h3>
                  <p>
                    The page you are looking for might have been removed had its
                    name changed or is temporarily unavailable.
                  </p>
                  <Link to="/" className="btn btn-primary">
                    Back To Homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default NotFound;
