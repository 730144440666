import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FinancedProjectsWorkItemTwo = ({ data ,financedProjectsData}) => {

  const shortText = (text, maxLength = 60) => {
    if (!text) {
      return " ";
    }
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + "...";
  };
  return (
    <div className="work">
      <div className="thumbnail">
        <Link className="image" to={`/financed-projects-details/${data?.id}`}>
          {data?.attributes?.images?.data &&
          data?.attributes?.images?.data[0]?.attributes?.url ? (
            <img
              src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data?.attributes?.images?.data[0]?.attributes?.url}`}
              alt="Financed projects"
            />
          ) : (
            <img src="/images/placeholder/expect-soon.png" alt="work" />
          )}
        </Link>
      </div>
      <div className="info">
        <h3 className="title">
          <Link to={`/financed-projects-details/${data?.id}`}>
            {shortText(data?.attributes?.name)}
          </Link>
        </h3>

        <Link
          to={`/financed-projects-details/${data?.id}`}
          className="btn btn-primary mt-2 text-white"
        >
          View More Details
        </Link>
      </div>
    </div>
  );
};

FinancedProjectsWorkItemTwo.propTypes = {
  data: PropTypes.object,
};

export default FinancedProjectsWorkItemTwo;
