import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import FinancedProjectsWorkItemTwo from '../../components/Work/FinancedProjectsWorkItemTwo.jsx';
const FinancedProjectsContainer = ({ financedProjectsData }) => {
  return (
    <div className="section section-padding-t90-b100">
      <div className="container">
        <SectionTitle headingOption="title fz-32" title="Financed Projects" />
        <div className="row mb-n6">
          <div className="col-12">
            <div className="row">
              {financedProjectsData?.map((single, key) => {
                return (
                  <div
                    key={key}
                    className="col-xl-4 col-lg-4 col-md-12 mb-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <FinancedProjectsWorkItemTwo
                      classOption="box-border"
                      data={single}

                      key={key}
                    />
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FinancedProjectsContainer;
