import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import IntroSlider from '../container/IntroSlider/IntroSlider';
import HomeServiceIconBox from '../container/service/HomeServiceIconBox';
import Funfact from '../container/Funfact/Funfact';
import HomeBlog from '../container/BlogGrid/HomeBlog';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ContactUsForm from '../container/ContactInformation/ContactUsForm';
import FeaturedProperty from '../container/NowSelling/FeaturedProperty';
import CoreValuesHome from '../components/CoreValuesHome';

const HomePage = () => {
  return (
    <React.Fragment>
      <SEO title="DeltaTrust" />
      <Header />
      <IntroSlider />
      <Funfact  classOption="bg-color-1" />
      <CoreValuesHome />
      <HomeServiceIconBox classOption="bg-color-1" />
      <FeaturedProperty />
      <HomeBlog />
      <ContactUsForm />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};
export default HomePage;
