import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from '.';
const AccordionWrap = () => {
  return (
    <div className="bank-accordion max-mb-n30">
      <Accordion>
        <AccordionItem id="one">
          <AccordionTitle id="one">What exactly is money?</AccordionTitle>
          <AccordionContent id="one">
            money is more than a logo and a clever tagline. Your brand tells the
            story of your company. It’s your message and your values. When that
            happens, remarkable things occur.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="two">
          <AccordionTitle id="two">
            What’s the money process like?
          </AccordionTitle>
          <AccordionContent id="two">
            money is more than a logo and a clever tagline. Your brand tells the
            story of your company. It’s your message and your values. When that
            happens, remarkable things occur.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem id="three">
          <AccordionTitle id="three">
            What type of professional services do you offer?
          </AccordionTitle>
          <AccordionContent id="three">
            money is more than a logo and a clever tagline. Your brand tells the
            story of your company. It’s your message and your values. When that
            happens, remarkable things occur.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionWrap;
