import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import BlogDetails from '../../components/Blog/BlogDetails.jsx';
import SidebarWrap from '../../components/Sidebar/SidebarWrap.jsx';
import SidebarWidget from '../../components/Sidebar/SidebarWidget.jsx';
import SidebarBanner from '../../components/Sidebar/SidebarBanner.jsx';
import SidebarTitle from '../../components/Sidebar/SidebarTitle';
import SidebarSearch from '../../components/Sidebar/SidebarSearch.jsx';
import SidebarCategories from '../../components/Sidebar/SidebarCategories.jsx';
import SidebarPost from '../../components/Sidebar/SidebarPost.jsx';
import SidebarTag from '../../components/Sidebar/SidebarTag.jsx';

const BlogDetailsContainer = ({ data }) => {
  return (
    <div className="section section-padding fix">
      <div className="container">
        <div className="row mb-n10">
          <div className="col-lg-8 col-12 order-lg-1 mb-10">
            <div className="row row-cols-1 no-gutters">
              <BlogDetails data={data} />

              <div className="blog-nav-links"> 
                <h4 className="title">Related Posts </h4>
                <div className="nav-list">
                  {data?.attributes?.blogs?.data?.map((val)=>(
                    <div className="nav-item prev">
                    <div className="inner">
                      <Link to={`/blog-details/${val.id}`}>
                        <div
                          className="hover-bg has-thumbnail"
                          style={{
                            backgroundImage: `url(./images/pagination/blog-pagination.jpg)`,
                          }}
                        ></div>
                        <span className="cate">{val.attributes?.category}</span>
                        <h6>
                        {val.attributes?.title}
                        </h6>
                      </Link>
                    </div>
                  </div>)
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className="col-lg-4 col-12 order-lg-2 mb-10">
            <SidebarWrap>
              <SidebarWidget>
                <SidebarSearch />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle title="Categories" />
                <SidebarCategories />
              </SidebarWidget>
              {/* <SidebarWidget>
                <SidebarTitle classOption="mb-2" title="Popular Posts" />
                <SidebarPost />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarBanner />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle title="Popular tags" />
                <SidebarTag />
              </SidebarWidget> */}
            </SidebarWrap>
          </div>
        </div>
      </div>
    </div>
  );
};
BlogDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default BlogDetailsContainer;
