import React from 'react';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import SectionTitleTwo from '../components/SectionTitles/SectionTitleTwo';
import CallToAction from '../container/CallToAction/CallToAction';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import { useEffect, useState } from 'react';
import { fetchGlobal } from '../utils/apis';
import { ServiceEndpoint } from '../APIs';
import ContactSalesFromContainer from '../container/ContactFromContainer/ContactSalesFormContainer';

const ServiceDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [service, setService] = useState([])
  useEffect(() => {
    const getService = async () => {
      const response = await fetchGlobal(ServiceEndpoint(id))
      !response.hasError && setService(response.data.data)
    }
    getService()
  }, [window.location.href])
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Service Details" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        content="Home"
        title={service?.attributes?.name}
        contentThree="Service"
        contentTwo={service?.attributes?.name}
      />

      <div className="section section-padding-top technology-section-padding-bottom-180 mb-10">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
              <div className="about-image-area">
                <div className="about-image">
                  <Tilt transitionSpeed={4000}>
                  </Tilt>
                </div>
                <div className="about-image">
                  <Tilt transitionSpeed={4000}>
                  </Tilt>
                </div>
                <div className="shape shape-1" id="scene">
                  <span data-depth="1">
                    {
                      service?.attributes?.image.data.attributes.url ?
                      <img
                        src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${service?.attributes?.image.data.attributes.url}`}
                        alt=""
                      />
                      :
                      <img
                        src="/images/placeholder/expect-soon.png"
                        alt=""
                      />
                    }
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col-xl-5 col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-content-area">
                <SectionTitleTwo
                  subTitle="Why choose this service?"
                  title={service?.attributes?.name}
                />

                <p>
                  {service?.attributes?.desc}
                  <hr />

                  <h3>
                    <strong>Features</strong>
                  </h3>
                  {service?.attributes?.features&& (
                    <ul>
                      {service?.attributes?.features?.map((val, i) => (
                          <li key={i}>{val.feature}</li>
                      ))}
                    </ul>
                  )}

                  <hr />

                  <h3>
                    <strong>Benefits</strong>
                  </h3>
                  {service?.attributes?.benefits && (
                      <ul>
                        {service?.attributes?.benefits?.map((val, i) => (
                          <li key={i}>{val.benefit}</li>
                        ))}
                      </ul>
                  )}

                  <hr />

                  <h3>
                    <strong>Requirements</strong>
                  </h3>

                  <ul>
                    {service?.attributes?.requirements?.map((val, i) => (
                      <li key={i}>{val.requirement}</li>
                    ))}
                  </ul>


                  <hr />
                </p>

                <Link
                  className="btn btn-primary btn-hover-secondary mt-4"
                  to="/ask-opening-account-form"
                >
                  Open an account now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactSalesFromContainer />
      <CallToAction />

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

ServiceDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default ServiceDetails;
