import PropTypes from "prop-types";
import React from "react";
import BlogClassic from "../../components/Blog/BlogClassic.jsx";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarSearch from "../../components/Sidebar/SidebarSearch.jsx";
import SidebarCategories from "../../components/Sidebar/SidebarCategories.jsx";
import { fetchGlobal } from "../../utils/apis";
import { useEffect, useState } from "react";
import { BlogsEndpoint } from "../../APIs";

const BlogClassicContainer = () => {
  const [page, setPage] = useState(1);
  const [blogs, setBLogs] = useState([]);
  const [nextPageButton, setNextPageButton] = useState(false);
  useEffect(() => {
    const getBlogs = async () => {
      const response = await fetchGlobal(BlogsEndpoint(page));
      !response.hasError && setBLogs(response.data.data);

      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const getNextPage = async () => {
      const response = await fetchGlobal(BlogsEndpoint(page + 1));

      if (!response.hasError && response.data.data.length) {
         setNextPageButton(true)
        } else {
        setNextPageButton(false)
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    getBlogs();
    getNextPage();
  }, [page]);

  return (
    <div className="section section-padding fix">
      <div className="container">
        <div className="row mb-n10">
          <div className="col-lg-8 col-12 order-lg-1 mb-10">
            <div className="row row-cols-1 no-gutters">
              {blogs?.map((single, key) => (
                <div key={key} className="col mb-6" data-aos="fade-up">
                  <BlogClassic data={single} key={key} />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col">
                <ul className="pagination center">
                  <li
                    onClick={() => {
                      page - 5 <= 0 ? setPage(1) : setPage(page - 5);
                    }}
                  >
                    <span className="prev">
                      <i className="fal fa-angle-left"></i>
                    </span>
                  </li>
                  <li
                    style={{ opacity: nextPageButton ? 1 : 0.5 }}
                    onClick={() => {
                      if (nextPageButton) {
                        setPage((prevPage) => prevPage + 1);
                      }
                    }}
                  >
                    <span className="next">
                      <i className="fal fa-angle-right"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-12 order-lg-2 mb-10">
            <SidebarWrap>
              <SidebarWidget>
                <SidebarSearch />
              </SidebarWidget>
              <SidebarWidget>
                <SidebarTitle title="Categories" />
                <SidebarCategories />
              </SidebarWidget>
            </SidebarWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogClassicContainer.propTypes = {
  data: PropTypes.object,
};

export default BlogClassicContainer;
