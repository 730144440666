import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PrivatePolicyContainer = () => {
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState('');

  useEffect(() => {
    const fetchUrls = async () => {
      try { 
        console.log(`strapi url: ${process.env.REACT_APP_STRAPI_URL}`)
        //const privacyResponse = await axios.get('http://localhost:1337/api/terms-privacy/get-privacy');
        const privacyResponse =  await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/terms-privacy/get-privacy`);
        setPrivacyPolicyUrl(privacyResponse.data.url);
      } catch (error) {
        console.error('Error fetching PDF URLs:', error);
      }
    };

    fetchUrls();
  }, []);

  if (!privacyPolicyUrl) {
    return <p>Loading Privacy Policy...</p>
  }

  return (
    <div className="section bg-primary-blue" >
      <iframe
        src={privacyPolicyUrl}
        width="100%"
        height="1800px"
        title="Privacy Policy"
        style={{ border: 'none' }}
      >
        This browser does not support PDFs. Please download the PDF to view it: 
        <a href={privacyPolicyUrl}>Download Privacy Policy</a>
      </iframe>
    </div>
   
  );
};

export default PrivatePolicyContainer;
