import PropTypes from 'prop-types';
import React from 'react';
import GoogleMap from '../../container/Map/GoogleMap';

const ContactInfoItem = ({ data }) => {
  return (
    <div className="contact-info">
      <div className="icon">
      </div>
      <div className="info">
        <h4 className="title">{data?.officeName}</h4>
        {
          data?.writtenLocation?
          <span
            className="info-text"
            dangerouslySetInnerHTML={{ __html: data?.writtenLocation }}
          />:
          <br/>
        }

        {
          data?.number?.map((val)=>(

            <span
              className="info-text"
              dangerouslySetInnerHTML={{ __html: val.number }}
            />
          ))
        }

        {
          data?.email?.map((val)=>(
              <span
              className="info-text"
              dangerouslySetInnerHTML={{ __html: val.email }}
            />
            )
          )
        }
        <GoogleMap location={data.location} />
      </div>
    </div>
  );
};

ContactInfoItem.propTypes = {
  data: PropTypes.object,
};

export default ContactInfoItem;
