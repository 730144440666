import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const AskOpenFormComponent = () => {
  const [userType, setUserType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bvn, setBvn] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const RefreshPage = () => {
    window.location.reload();
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);

    if (event.target.value === "new") {
      history.push("/opening-account-form");
    } else {
      setAccountNumber("");
    }
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleBvnChange = (event) => {
    setBvn(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (userType === "existing") {
      setIsSubmitting(true);
      setError(null);

      // Make API call with the entered account number and BVN
      console.log("static IP cname ", process.env.REACT_APP_STATIC_IP_CNAME);
      fetch(
        `${process.env.REACT_APP_STATIC_IP_CNAME}/api/get-accounts-by-bvn?bvn=${bvn}&accountNumber=${accountNumber}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            console.log("Success:", data);
            // Handle the successful response
            setApiResponse(data);
            setError(null); // Clear any previous error
          } else if (data.status === "failure") {
            console.log("Failure:", data.errMessage);
            // Handle the failure response
            setApiResponse(null); // Clear any previous response
            setError(data.errMessage);
          } else {
            console.log("Unknown response from banking:", data);
            // Handle other response types if needed
            setError(`Error Occured ${JSON.stringify(data)}`);
          }
        })
        .catch((error) => {
          console.error(error);
          console.log("Error occurs", error.stack);
          setApiResponse(null); // Clear any previous response
          setError(`An error occurred: ${error.status}`);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <div className="col-12" style={{ padding: "50px" }}>
      <div className="section section-padding-t90-b100">
        <div className="container">
          <div className="text-center mb-10">
            <h1 className="text-primary">
              {" "}
              Account Opening Form <br />
            </h1>
            <div className="info text-center">
              Welcome to the Delta Trust Mortgage Bank Online Account Opening
              Web Engine, <br /> where you can initiate the process of opening
              an account and get an account number instantly.
            </div>
          </div>
          <div className="text-center mb-40">
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 d-flex justify-content-center align-items-center">
              <div
                className="col mb-6 aos-init aos-animate text-center"
                data-aos="fade-up"
              >
                <div className="contact-info">
                  <div>
                    <h3 className="title pb-5 text-left" data-aos="fade-up">
                      Step 1 of 2: Account Choices
                    </h3>
                    <hr />
                    <form onSubmit={handleSubmit} className="text-center">
                      <div className="text-left">
                        <label htmlFor="userType">
                          <strong>What Would You Like To Do? &nbsp;</strong>
                        </label>
                        <select
                          id="userType"
                          value={userType}
                          onChange={handleUserTypeChange}
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            width: "300px",
                          }}
                        >
                          <option value="">Select an answer</option>
                          <option value="new">Open a New Account</option>
                          <option value="existing">
                            Open an Additional Account
                          </option>
                        </select>
                      </div>
                      {userType === "existing" && (
                        <div className="section section-padding-t90 pb-10">
                          <h3
                            className="title pb-5 text-left"
                            data-aos="fade-up"
                          >
                            Step 1: Account Validation
                          </h3>
                          <div className="info text-left">
                            Validate your existing account with Account number
                            and BVN{" "}
                          </div>
                          <hr />
                          <div className="text-left">
                            <label htmlFor="accountNumber">
                              <strong>Enter your account number</strong>
                            </label>
                            <input
                              type="text"
                              id="accountNumber"
                              value={accountNumber}
                              onChange={handleAccountNumberChange}
                            />
                          </div>
                          <br />
                          <div className="text-left ">
                            <label htmlFor="bvn">
                              <strong>Enter your BVN</strong>
                            </label>
                            <input
                              type="text"
                              id="bvn"
                              value={bvn}
                              onChange={handleBvnChange}
                            />
                          </div>
                        </div>
                      )}

                      {userType === "existing" && (
                        <button
                          type="submit"
                          className={`${
                            isSubmitting || !accountNumber || !bvn
                              ? "disabled"
                              : ""
                          }` } 
                          style={{
                            display: "inline-block",
                            backgroundColor: "#FD840C",
                            color: "white",
                            opacity:
                              isSubmitting || !accountNumber || !bvn ? 0.5 : 1,
                          }}
                          disabled={isSubmitting || !accountNumber || !bvn}
                        >
                          {isSubmitting ? (
                            <>
                              <img
                                src="images/6-dots-rotate.svg"
                                alt="Looking for your account..."
                                style={{ width: "20px", marginRight: "8px" }}
                              />
                             Looking for your account...
                            </>
                          ) : (
                            "Validate Account"
                          )}
                        </button>
                      )}
                    </form>
                    <div className="pb-5"></div>
                    {error && <p style={{ color: "red" }}>Error: {error}</p>}

                    {apiResponse && (
                     
                      <div>
                    
                      <div
                        className="section"
                        style={{
                          border: "2px solid green",
                          padding: "20px",
                          borderRadius: "10px",
                          boxSizing: "border-box",
                        }}
                      >
                        <h3
                          className="title pb-5 text-center"
                          data-aos="fade-up"
                        >
                          Great news!
                        </h3>
                        <div className="info text-center">
                          We found a matching account. <br /> Please confirm if
                          these are your details
                        </div>
                        <hr />

                        <div className="text-centre">
                          <strong>
                            Account Number:{" "}
                            {apiResponse.account_details.AccountNumber}
                          </strong>
                        </div>

                        <div className="text-centre">
                          <strong>
                            Last Name: {apiResponse.account_details.LastName}
                          </strong>
                        </div>

                        <div className="text-centre">
                          <strong>
                            {" "}
                            Date of Birth:{" "}
                            {new Date(
                              apiResponse.account_details.DateOfBirth
                            ).toLocaleDateString()}
                          </strong>
                        </div>

                        <div className="text-centre">
                          <br />
                          <Link
                            to={{
                              pathname: "/opening-account-form",
                              state: {
                                customerID:
                                  apiResponse.account_details.CustomerID,
                                accountNumber:
                                  apiResponse.account_details.AccountNumber,
                                lastName: apiResponse.account_details.LastName,
                                otherNames:
                                  apiResponse.account_details.OtherNames,
                                address: apiResponse.account_details.Address,
                                email_s: apiResponse.account_details.Email,
                                dateOfBirth:
                                  apiResponse.account_details.DateOfBirth,
                                bankVerificationNumber:
                                  apiResponse.account_details
                                    .BankVerificationNumber,
                                phoneNumber:
                                  apiResponse.account_details.PhoneNumber,
                              },
                            }}
                            className="btn btn-success justify-content-left"
                          >
                            Yes, Continue
                          </Link>
                        {" "}  
                        <button className="btn btn-danger" onClick={RefreshPage}>
        No, start over 
      </button>
                        </div>
                      </div>
                      </div>
                    )}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskOpenFormComponent;
