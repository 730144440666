import React, { useState } from 'react'

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderWidth: 4,
  borderRadius: 2,
  borderColor: '#FD840C',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const UploadForm = () => {
  const [file, setFile] = useState(null)
  const [customerId, setCustomerId] = useState('')
  const [uploadStatus, setUploadStatus] = useState({ status: '', message: '' })
  const [isUploading, setIsUploading] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  const [fileError, setFileError] = useState('')

  const maxSize = 200000 // 200KB

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    validateFile(selectedFile)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setDragActive(true)
  }

  const handleDragLeave = () => {
    setDragActive(false)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setDragActive(false)
    const droppedFile = event.dataTransfer.files[0]
    validateFile(droppedFile)
  }

  const validateFile = (file) => {
    if (file) {
      if (file.size > maxSize) {
        setFileError('File is too large. Maximum size is 200KB.')
        setFile(null)
      } else {
        setFile(file)
        setFileError('')
      }
    }
  }

  const handleUploadSubmit = (event) => {
    event.preventDefault()

    if (!file) {
      setUploadStatus({
        status: 'failure',
        message: 'Please select a file to upload',
      })
      return
    }

    if (!customerId) {
      setUploadStatus({
        status: 'failure',
        message: 'Please enter your Customer ID',
      })
      return
    }

    setIsUploading(true)

    fileToBase64(file)
      .then((base64Data) => {
        const data = {
          CustomerID: customerId,
          CustomerPassportInBytes: base64Data,
        }

        fetch(`${process.env.REACT_APP_STATIC_IP_CNAME}/api/acct-update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              setUploadStatus({
                status: 'success',
                message:
                  "File uploaded successfully. <br/> Check out our <a href='/NowSelling' style={{ color: '#FD840C' }}>'Now Selling' </a> Properties",
              })
            } else {
              setUploadStatus({
                status: 'failure',
                message: data.data ? data.data.Message : 'An error occurred',
              })
            }
          })
          .catch((error) => {
            console.error('Error:', error)
            setUploadStatus({
              status: 'failure',
              message: 'An error occurred',
            })
          })
          .finally(() => {
            setIsUploading(false)
          })
      })
      .catch((error) => {
        console.error('Error:', error)
        setUploadStatus({ status: 'failure', message: 'An error occurred' })
        setIsUploading(false)
      })
  }

  return (
    <div className='col-12' style={{ padding: '50px' }}>
      <div className='section section-padding-t90-b100'>
        <div className='container'>
          <div className='text-center mb-10'>
            <h1 className='text-primary'>
              Account Update Form
              <br />
            </h1>
            <div className='info text-center'>
              Welcome to the Delta Trust Mortgage Bank Online Account Update
              Portal, <br />
              where you can upload documents to update your account information
              efficiently.
            </div>
          </div>
          <form onSubmit={handleUploadSubmit}>
            <input
              type='text'
              placeholder='Enter Customer ID'
              value={customerId}
              style={{ marginBottom: '25px' }}
              onChange={(e) => setCustomerId(e.target.value)}
              required
            />
            <div
              style={{
                ...baseStyle,
                borderColor: dragActive ? '#00e676' : '#FD840C',
              }}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                type='file'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id='fileInput'
              />
              <label htmlFor='fileInput' style={{ cursor: 'pointer' }}>
                {dragActive ? (
                  <p>Drop your I.D files here ...</p>
                ) : (
                  <div>
                    <i
                      className='fa fa-upload'
                      style={{ fontSize: '24px', marginBottom: '10px' }}
                    ></i>
                    <p>
                      {file
                        ? `Selected file - ${file.name}`
                        : "Drag 'n' drop some files here, or click to select files. Max allowed size is 200KB"}
                    </p>
                  </div>
                )}
              </label>
            </div>
            {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
            <div className='mb-5'></div>
            <button
              style={{ backgroundColor: '#FD840C', color: 'white' }}
              type='submit'
              disabled={isUploading}
            >
              {isUploading ? (
                <>
                  <img
                    src='images/6-dots-rotate.svg'
                    alt='Loading...'
                    style={{ width: '20px', marginRight: '8px' }}
                  />
                  Uploading...
                </>
              ) : (
                'Upload File'
              )}
            </button>
          </form>

          {uploadStatus.message && (
            <div
              style={{
                color: uploadStatus.status === 'success' ? 'green' : 'red',
              }}
              dangerouslySetInnerHTML={{ __html: uploadStatus.message }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadForm
