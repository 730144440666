import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SingleTeam = ({ data, type }) => {
  return (
    <div className="team">
      <div className="thumb">
        {
          data?.attributes?.image?.data?.attributes?.url ?
          <img
            src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data?.attributes?.image?.data?.attributes?.url}`}
            alt="team Image"
          />
          :
          <img
            src="/images/placeholder/person.png"
            alt="team Images"
          />
        }
        <div className="overlay"></div>
        <ul className="social-icon text-center">
          {
              data.attributes?.facebookLink && (
                <li className="facebook">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.attributes.facebookLink}
                  className="link"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
            )
          }
          {
              data.attributes?.instagramLink && (
                <li className="instagram">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.attributes.instagramLink }
                  className="link"
                  aria-label="Instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            )
          }
          {
              data.attributes?.twitterLink && (
                <li className="twitter">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.attributes.twitterLink}
                  className="link"
                  aria-label="Twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            )
          }
        </ul>
      </div>
      <div className="team-info text-center">
        <div className="info">
          <h5>{data.attributes?.name}</h5>
          <span>{data.attributes?.position}</span>

        </div>
            <Link className="btn btn-primary btn-sm my-4" to={`/${type}/${data.id}`}>View Profile</Link>  
      </div>
    </div>
  );
};

SingleTeam.propTypes = {
  data: PropTypes.object,
};

export default SingleTeam;
