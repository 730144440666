import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ReactVivus from "react-vivus";

const ServiceIconBox = ({ data, classOption, imageSVG }) => {
  return (
    <div className={`icon-box text-center ${classOption}`}>
      <div className="icon">
        {data?.attributes?.image?.data && (
          <Link to={`/service-details/${data.id}`} className="image">
            <ReactVivus
              id={`servicesvg-${data.id}`}
              option={{
                file: imageSVG,
                animTimingFunction: "EASE",
                type: "oneByOne",
                delay: 80,
              }}
            />
          </Link>
        )}
      </div>
      <div className="content">
        <h3 className="title">{data?.attributes?.name}</h3>
        <div className="desc">
          <p>{data?.attributes?.desc}</p>
        </div>
        <Link to={`/service-details/${data?.id}`} className="link">
          Learn More
        </Link>
      </div>
    </div>
  );
};

ServiceIconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

ServiceIconBox.defaultProps = {
  classOption: "icon-box text-center",
};

export default ServiceIconBox;
