import { useState, useEffect, useRef } from 'react';
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    
    <div className="section section-padding-top section-padding-bottom-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={'images/about/about-3.jpg'} alt="" />
                </Tilt>
              </div>
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={'images/about/about-4.jpg'} alt="" />
                </Tilt>
              </div>

              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={'images/shape-animation/contact-shape.png'}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >

            
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Our Vision"
              />

<div
                  className="col-12 mt-2 mb-6 "
                  style={{
                    fontSize: '1.6rem',
                  }}
                >
              <p>
              To be the mortgage bank of choice in the delivery of affordable housing and other financial services for the economic benefit of all stakeholders
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
