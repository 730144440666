import React from 'react'
import SEO from '../components/SEO'
import Header from '../partials/header/Header'
import Breadcrumb from '../container/Breadcrumb/Breadcrumb'
import Footer from '../container/Footer/Footer'
import ScrollToTop from '../components/ScrollToTop.jsx'
import CallToAction from '../container/CallToAction/CallToAction'
import UploadForm from '../components/UploadDocs2'

const UploadDocsForm = () => {
  return (
    <React.Fragment>
      <SEO title='DeltaTrust || Upload Documents' />
      <Header />
      <Breadcrumb
        image='images/bg/video-bg.jpg'
        title='Upload Your Documents'
        content='Home'
        contentTwo='Upload Documents'
      />
      <br></br>
      <UploadForm />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  )
}

export default UploadDocsForm
