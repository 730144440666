import React, { useEffect } from 'react';

function ChatComponent() {

  useEffect(() => {
    const script = document.createElement("script");
    
    script.src = "https://app.chatwoot.com/packs/js/sdk.js";
    script.async = true;

    script.onload = function() {
      window.chatwootSDK.run({
        websiteToken: process.env.REACT_APP_LIVECHAT_TOKEN || 'dev',
        baseUrl: 'https://app.chatwoot.com'
      });
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return <div />;
}

export default ChatComponent;
