import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import PrivatePolicyContainer from '../container/PolicyTerms/PrivacyPolicy.js';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const PrivacyPolicyPage = () => {
    return (
        <React.Fragment>
            <SEO title="DeltaTrust Privavy Policy" />
            <Header />
            <Breadcrumb
        image="images/bg/bg-contact-.jpg"
        title="Delta Trust is committed to protecting your privacy"
        content="Home"
        contentTwo="Privacy Policy"
      />
            <PrivatePolicyContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default PrivacyPolicyPage;