import React from "react";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import services from "../../../data/iconBox/icon-box.json";

const MobileNavMenu = () => {
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("menu-toggle") ||
      target.classList.contains("menu-toggle")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 0);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 0);
        }
      });
    }
  };
  return (
    <nav className="site-mobile-menu">
      <ul>
        <li className="has-children">
          <NavLink to={"/"}>
            <span className="menu-text">Home</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={"/about"}>
            <span className="menu-text">About Us</span>
          </NavLink>
          <span className="menu-toggle" onClick={onClickHandler}>
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            {/* Corporate Governance */}
            <li>
              <strong>Corporate Governance</strong>
            </li>
            <li>
              <a href={`/about#board-of-directors`}>
                <span className="menu-text">Board of Directors</span>
              </a>
            </li>
            <li>
              <a href={`/about#management-team`}>
                <span className="menu-text">Management Team</span>
              </a>
            </li>

            {/* Business Focus */}
            <li>
              <strong>Business Focus</strong>
            </li>
            <li>
              <a href={`/#core-values`}>
                <span className="menu-text">Core Values</span>
              </a>
            </li>
            <li>
              <a href={`/#score-board`}>
                <span className="menu-text">Score Board</span>
              </a>
            </li>
            <li>
              <NavLink to={`/contact`}>
                <span className="menu-text">Office Locations</span>
              </NavLink>
            </li>

            {/* Serving You */}
            <li>
              <strong>Serving You</strong>
            </li>
            <li>
              <NavLink to={"/ask-opening-account-form"}>
                <span className="menu-text">Open an Account</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact"}>
                <span className="menu-text">Join us</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/photogallery`}>
                <span className="menu-text">Photo Gallery</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/contact`}>
                <span className="menu-text">Help & Support</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <NavLink to={"/service"}>
            <span className="menu-text">Products & Services</span>
          </NavLink>
          <span className="menu-toggle" onClick={onClickHandler}>
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
           <li>
              <strong>Payments</strong>
            </li>

            <li>
              <a href={'https://ibank.mybankone.com/deltatrus'} target='_blank'>
                <span className="menu-text">Loan Repayments</span>
              </a>
            </li>

            <li>
                <a href={'https://login.remita.net/remita/onepage/biller/payment.spa?recurringPayment=1'} target='_blank'>
                  <span className="menu-text">Manage Standing Order</span>
                </a>
              </li>

              <li>
                <a href={'https://docs.google.com/spreadsheets/d/1tYVxjDr-p4pMwRos_3vKi6OUDzLhiyKfvnI4uAgS-3w/edit#gid=541352024'} target='_blank'>
                  <span className="menu-text">Mortgage Calculator</span>
                </a>
              </li>

            

            <li>
              <strong>Service Products</strong>
            </li>
            {services.map((service) => (
              <li key={service.id}>
                <NavLink to={`/service-details/${service.id}`}>
                  <span className="menu-text">{service.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </li>

  
      <li>
          <NavLink to={"/financed-projects"}>
            <span className="menu-text">Financed Projects</span>
          </NavLink>
        </li>


        <li>
          <NavLink to={"/NowSelling"}>
            <span className="menu-text">Now Selling</span>
          </NavLink>
        </li>

      
        <li>
          <NavLink to={"/blog-classic"}>
            <span className="menu-text">Blogs & News</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/contact"}>
            <span className="menu-text">Contact Us</span>
          </NavLink>
        </li>

        <li>
          <a
            href="https://ibank.mybankone.com/deltatrust"
            className="btn btn-primary text-white text-decoration-none w-100 d-flex justify-content-center align-items-center"
          >
            Internet Banking
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
