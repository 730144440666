import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import WorkItemTwo from '../../components/Work/WorkItemTwo.jsx';
import { useEffect, useState } from 'react';
import { ListingsEndpoint } from '../../APIs';
import { fetchGlobal } from '../../utils/apis';
import { Link } from 'react-router-dom';
const NowSellingContainer = () => {
  const [listings, setListings] = useState([]);
  useEffect(() => {
    const getListings = async () => {
      const response = await fetchGlobal(ListingsEndpoint);
      !response.hasError && setListings(response.data.data);
    };
    getListings();
  }, []);
  return (
    <div className="section section-padding-t90-b100">
      <div className="container">
        <SectionTitle headingOption="title fz-32" title="Featured Properties" />

        <div className="row mb-n6">
          <div className="col-12">
            <div className="row">
              {listings?.map((single, key) => {
                return (
                  <div
                    key={key}
                    className="col-xl-4 col-lg-4 col-md-12 mb-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <WorkItemTwo
                      classOption="box-border"
                      data={single}
                      key={key}
                    />
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default NowSellingContainer;
