import React from 'react';
import PropTypes from "prop-types";
import BlogClassicData from '../../data/blog/BlogClassic.json';
import BlogItem from '../../components/Blog/BlogItem';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import { useEffect, useState } from 'react';
import { SelectedBlogsEnpoint } from '../../APIs';
import { fetchGlobal } from '../../utils/apis';

const HomeBlog =({ SectionBgColor }) => {
    const [blogs, setBlogs] = useState({})
    useEffect(() => {
        const getBlogs = async () => {
          const response = await fetchGlobal(SelectedBlogsEnpoint)
          !response.hasError && setBlogs(response.data.data)
        }
        getBlogs()
    }, [])
    
    return (
        <div className={`section section-padding-t90-b100 ${SectionBgColor}`}>
            <div className="container">
                <SectionTitle
                    title={blogs?.attributes?.title}
                    subTitle={blogs?.attributes?.desc}
                />

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                        {blogs?.attributes?.blogs?.data?.map((single, key) => {
                            return(
                                <div key={key} className="col mb-6" data-aos="fade-up">
                                    <BlogItem data={single} key={key} />
                                </div>
                            ); 
                        })}
                </div>
            </div>
        </div>
    )
}
HomeBlog.propTypes = {
    SectionBgColor: PropTypes.string
};
HomeBlog.defaultProps = {
    SectionBgColor: "section section-padding-t90-b100"
};

export default HomeBlog
