import React from 'react'

const GoogleMap = ({ location }) => {
    if (!location) {
        return <></>
    } else {
        location = JSON.parse(location)
    }

    return (
        <div className="google-map-area section text-center  my-4">
            <div className="flex">
                <div className="contact-map-area" data-aos="fade-up">
                    <iframe className="contact-map" src={`https://maps.google.com/maps?q=${location.lat},${location.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`}  aria-hidden="false" referrerPolicy= "no-referrer-when-downgrade" title="map"></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
