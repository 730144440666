import PropTypes from 'prop-types';
import React from 'react';
import ReactVivus from 'react-vivus';

const ContactInfoItemTwo = ({ title, text, classOption ,svg, id }) => {
  return (
    <div className={`contact-info ${classOption}`}>
      {(id&&svg) &&
        <div className="icon">
          <ReactVivus
            id={`contactsvg-${id}`}
            option={{
              file: svg,
              animTimingFunction: 'EASE',
              type: 'oneByOne',
              delay: 80,
            }}
          />
        </div> 
      }
      <div className="info">
        <h4 className="title">{title}</h4>
        <span
          className="info-text"
          style={{ wordBreak: 'break-word' }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

ContactInfoItemTwo.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
};

ContactInfoItemTwo.defaultProps = {
  classOption: 'contact-info ct-info-two',
};

export default ContactInfoItemTwo;
