import React, { useCallback, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 4,
  borderRadius: 2,
  borderColor: "#FD840C",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#fd840c",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const UploadForm = ({ accountNumber }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({ status: "", message: "" });
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const maxSize = 40000; // 40KB

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept:
      "image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    maxSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleUploadSubmit = (event) => {
    event.preventDefault();

    if (!file) {
      setUploadStatus({
        status: "failure",
        message: "Please select a file to upload",
      });
      return;
    }

    setIsUploading(true);

    fileToBase64(file)
      .then((base64Data) => {
        const data = {
          AccountNumber: accountNumber,
          CustomerImage: base64Data.replace(/^data:image\/[a-z]+;base64,/, ""),
        };

        fetch(`${process.env.REACT_APP_STATIC_IP_CNAME}/api/upload-docs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setUploadStatus({
                status: "success",
                message: "File uploaded successfully. <br/> Check out our <a href='/NowSelling' style={{ color: '#FD840C' }}>'Now Selling' </a> Properties",
              });
            } else {
              setUploadStatus({
                status: "failure",
                message: data.data ? data.data.Message : "An error occurred",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setUploadStatus({
              status: "failure",
              message: data.message
                ? data.message.Message
                : "An error occurred",
            });
          })
          .finally(() => {
            setIsUploading(false);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        setUploadStatus({ status: "failure", message: "An error occurred" });
        setIsUploading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleUploadSubmit}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop your I.D files here ...</p>
          ) : (
            <div>
              <i
                className="fa fa-upload"
                style={{ fontSize: "24px", marginBottom: "10px" }}
              ></i>
              <p>
                {file
                  ? `Selected file - ${file.name}`
                  : "Drag 'n' drop some files here, or click to select files. Max allowed size is 40KB"}
              </p>
            </div>
          )}
        </div>
        <div className="mb-5"></div>
        <button
          style={{ backgroundColor: "#FD840C", color: "white" }}
          type="submit"
          disabled={isUploading}
        >
          {isUploading ? (
            <>
              <img
                src="images/6-dots-rotate.svg"
                alt="Loading..."
                style={{ width: "20px", marginRight: "8px" }}
              />
              Uploading...
            </>
          ) : (
            "Upload File"
          )}
        </button>
      </form>

      {uploadStatus.message && (
       <div
       style={{ color: uploadStatus.status === "success" ? "green" : "red" }}
       dangerouslySetInnerHTML={{ __html: uploadStatus.message }}
     />
      )}
      {fileRejections.length > 0 && (
        <div style={{ color: "red" }}>
          {fileRejections.map(({ file, errors }) => (
            <div key={file.path}>
              {errors.map((e) => (
                <p key={e.code}>{e.message}</p>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UploadForm;
