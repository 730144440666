import React from "react";
import { NavLink } from "react-router-dom";
import { NavbarEndpoint } from "../../APIs";
import { fetchGlobal } from "../../utils/apis";
import { useEffect, useState } from "react";

const NavBar = () => {
  const [navbar, setNavbar] = useState({})
  useEffect(() => {
    const getNavbar = async () => {
      const response = await fetchGlobal(NavbarEndpoint)
      !response.hasError && setNavbar(response.data.data)
    }
    getNavbar()
  }, [])

  return (
    <nav className="site-main-menu">
      <ul>
      <li className="has-children">
          <NavLink to={"/about"}>
            <span className="menu-text">About Us</span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu d-flex w-auto">
            <div className="item">
              <h6>
                <span className="menu-text">Corporate Governance</span>
              </h6>
              <li>
                <NavLink to={"/about"}>
                  <span className="menu-text">About us</span>
                </NavLink>
              </li>
              <li>
                <a href={`/about#board-of-directors`}>
                  <span className="menu-text">Board of Directors</span>
                </a>
              </li>
              <li>
                <a href={`/about#management-team`}>
                  <span className="menu-text">Management Team</span>
                </a>
              </li>
            </div>

            <div className="item">
              <h6>
                <span className="menu-text">Business Focus</span>
              </h6>
              <li>
                <a href={`/#core-values`}>
                  <span className="menu-text">Core Values</span>
                </a>
              </li>
              <li>
                <a href="/#score-board">
                  <span className="menu-text">Score Board</span>
                </a>
              </li>
              <li>
                <NavLink to={`/contact`}>
                  <span className="menu-text">Office Locations</span>
                </NavLink>
              </li>
            </div>
            <div className="item">
              <h6>
                <span className="menu-text">Serving You</span>
              </h6>
              <li>
                <NavLink to={"/ask-opening-account-form"}>
                  <span className="menu-text">Open an Account</span>
                </NavLink>
              </li>
          
              <li>
                <NavLink to={`/photogallery`}>
                  <span className="menu-text">Photo Gallery</span>
                </NavLink>
              </li>
              
            </div>
          </ul>
        </li>
        <li className="has-children">
          <NavLink to={"/service"}>
            <span className="menu-text">Products & Services</span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu d-flex w-auto">
          
          <div className="item">
              <h6>
                <span className="menu-text">Payments</span>
              </h6>
              <li>
                <a href={'https://ibank.mybankone.com/deltatrust'} target='_blank'>
                  <span className="menu-text">Loan Repayments</span>
                </a>
              </li>
              <li>
                <a href={'https://login.remita.net/remita/onepage/biller/payment.spa?recurringPayment=1'} target='_blank'>
                  <span className="menu-text">Manage Standing Order</span>
                </a>
              </li>

              <li>
                <a href={'https://docs.google.com/spreadsheets/d/1tYVxjDr-p4pMwRos_3vKi6OUDzLhiyKfvnI4uAgS-3w/edit#gid=541352024'} target='_blank'>
                  <span className="menu-text">Mortgage Calculator</span>
                </a>
              </li>
        
            </div>

            <div className="item">
            <h6>
                <span className="menu-text">Service Product</span>
              </h6>
            <li>
              <NavLink to={"/service"}>
                <span className="menu-text">All Services</span>
              </NavLink>
            </li>

            {
              navbar?.attributes?.services?.map((val) => (
                <li key={val.id}>
                  <NavLink to={`/service-details/${val.service?.data?.id}`}>
                    <span className="menu-text">{val.name}</span>
                  </NavLink>
                </li>
            ))}
            </div>

         


          </ul>
        </li>
        <li>
          <NavLink to={"/financed-projects"}>
            <span className="menu-text">Financed Projects</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/NowSelling"}>
            <span className="menu-text">Now Selling</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/blog-classic"}>
            <span className="menu-text">Blogs & News</span>
          </NavLink>
        </li>
      
        <li className="has-children">
          <NavLink to={"/contact"}>
            <span className="menu-text">Career</span>
          </NavLink>
          <ul className="sub-menu d-flex w-auto">
          <div className="item">

          <li>
                <NavLink to={"/contact"}>
                  <span className="menu-text">Join us</span>
                </NavLink>
              </li>
              
          <li>
                <NavLink to={`/contact`}>
                  <span className="menu-text">Help & Support</span>
                </NavLink>
              </li>
              
        
            </div>
            </ul>
        </li>





      </ul>
    </nav>
  );
};

export default NavBar;
