import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ServiceIconBox from "../../components/IconBox/ServiceIconBox.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from "parallax-js";
import { fetchGlobal } from "../../utils/apis";
import { ServicesEndpoint } from "../../APIs";
import servicesIcons from "../../data/icons/services-icons.json";

const ServiceIconBoxTwo = ({ classOption }) => {
  const [services, setServices] = useState([]);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  useEffect(() => {
    const getServices = async () => {
      const response = await fetchGlobal(ServicesEndpoint);
      !response.hasError && setServices(response.data.data);
    };
    getServices();
  }, []);
  return (
    <div
      className={`section section-padding-t90 section-padding-bottom ${classOption}`}
    >
      <div className="container">
        <SectionTitle
          headingOption="fz-32"
          title="Home Financing Solutions: Our Products and Services"
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-box-shape-animation">
          {services?.map((single, key) => {
            return (
              <div
                key={key}
                className="col mb-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ServiceIconBox
                  imageSVG={
                    servicesIcons[Math.min(key, servicesIcons.length - 1)].icon
                  }
                  classOption="box-border"
                  data={single}
                  key={key}
                />
              </div>
            );
          })}

          <div className="shape shape-1" id="scene" ref={sceneEl}>
            <span data-depth="1">
              <img src={"/images/shape-animation/video-shape-1.png"} alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ServiceIconBoxTwo.propTypes = {
  classOption: PropTypes.string,
};
ServiceIconBoxTwo.defaultProps = {
  classOption: "section section-padding-t90 section-padding-bottom",
};

export default ServiceIconBoxTwo;
