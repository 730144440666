import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TermsofUseContainer = () => {
  const [termsUrl, setTermsUrl] = useState('');

  useEffect(() => {
    const fetchUrls = async () => {
      try { 
        console.log(`strapi url: ${process.env.REACT_APP_STRAPI_URL}`)
        const termsResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/terms-privacy/get-terms`);
        //console.log('Terms Response:', termsResponse);  // Print the response to the console
        setTermsUrl(termsResponse.data.url);
      } catch (error) {
        console.error('Error fetching PDF URLs:', error);
      }
    };

    fetchUrls();
  }, []);

  if (!termsUrl) {
    return <p>Loading Terms of Use...</p>
  }

  return (

    <div className="section bg-primary-blue" >

      <iframe
        src={termsUrl}
        width="100%"
        height="1800px"
        title="Terms of Use"
        style={{ border: 'none' }}
      >
        This browser does not support PDFs. Please download the PDF to view it: 
        <a href={termsUrl}>Download Terms of Use</a>
      </iframe>
    </div>
  
  );
};

export default TermsofUseContainer;
