import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Intro =  ({data}) => {
    return (
        <div className="intro-section overlay section" style={{backgroundImage: `url(${process.env.REACT_APP_STRAPI_MEDIA_URL}${data?.attributes?.backgroundImage?.data?.attributes?.url})`}}>

            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">

                    <div className="col align-self-center">
                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                            <h2 className="title">{data?.attributes?.content}</h2>
                            <Link to={`/${data?.attributes?.slug}`} className="btn btn-primary btn-hover-secondary">
                                {data?.attributes?.buttonText}
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        
    )
}

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro
