import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import ContactPerson from '../ContactInformation/ContactPerson';
import GoogleMap from '../Map/GoogleMap';
import { ProjectsEndpoint } from '../../APIs';
import { fetchGlobal } from '../../utils/apis';

SwiperCore.use([Navigation, Autoplay]);

const FinancedProjectsDetailsContainer = ({ data }) => {
  // const sliceText = (text) => {
  //   return text.slice(0, 60);
  // };
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getProjects = async () => {
      const response = await fetchGlobal(ProjectsEndpoint);
      !response.hasError && setProjects(response.data.data);
    };
    getProjects();
  }, []);

  console.log(projects);

  const swiperOption = {
    centeredSlides: true,
    loop: true,
    speed: 750,
    spaceBetween: 40,
    slidesPerView: 1.5,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1.5,
      },
      992: {
        slidesPerView: 2.1,
      },
    },
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.intro-slider .swiper-button-next',
      prevEl: '.intro-slider .swiper-button-prev',
    },
    modules: [Autoplay],
  };
  const html = `<ul>
    ${data.attributes?.information
      ?.split('\n')
      .map((line) =>
        line.startsWith('**')
          ? `
      <li><strong>${line.substring(2)}</strong></li>`
          : `
      <li>${line.trim()}</li>`
      )
      .join('')}
  </ul>`;
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-8">
            <div className="col-12 pb-3">
              <div className="work-left work-details" data-aos="fade-up">
                <div className="portfolio-main-info pb-3">
                  <h2 className="title"> {data.attributes?.name} </h2>

                  <div className="imageSlides my-5">
                    <Swiper
                      effect="fade"
                      className="intro-slider"
                      {...swiperOption}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {data?.attributes?.images?.data &&
                        data?.attributes?.images?.data?.map((val) => (
                          <SwiperSlide key={val}>
                            <img
                              src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${val?.attributes?.url}`}
                              alt={data.attributes?.name}
                            />
                          </SwiperSlide>
                        ))}
                      <div
                        className="home-slider-next main-slider-nav swiper-button-next bg-primary text-white"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <i className="fal fa-angle-right"></i>
                      </div>
                      <div
                        className="home-slider-prev main-slider-nav swiper-button-prev bg-primary text-white"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <i className="fal fa-angle-left"></i>
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-lg-12 mb-9 pb-9">
              {data.attributes?.contact_people?.data.length > 0 && (
                <h2 className="title text-center pb-5" data-aos="fade-up">
                  Contact Persons
                </h2>
              )}
              <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                {data.attributes?.contact_people?.data &&
                  data.attributes?.contact_people?.data.map((person) => (
                    <ContactPerson person={person?.attributes || {}} />
                  ))}
              </div>
              <hr />
              <div className="col-12 my-5">
                <div className="work-left work-details mt-6">
                  <div className="work-main-info">
                    <div className="work-content">
                      <h2 className="title" data-aos="fade-up">
                        More Details :
                      </h2>

                      <div className="desc mt-8">
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>

                        <GoogleMap location={data.attributes?.location} />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3 className="title fz-24 mb-6 text-center">Also Check </h3>
            <ul className="list-group">
              {projects.map((single, key) => {
                return (
                  <li key={key} className="list-group-item ">
                    <a href={`/financed-projects-details/${single.id}`}>
                      {single.attributes?.name}....
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div
                className="custom-column-thumbnail mt-lg-14 mt-1"
                data-aos="fade-up"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FinancedProjectsDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default FinancedProjectsDetailsContainer;
