import React from 'react';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import FinancedProjectsDetailsContainer from '../container/FinancedProjects/FinancedProjectsDetailsContainer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { useEffect, useState } from 'react';
import { ProjectEndpoint } from '../APIs';
import { fetchGlobal } from '../utils/apis';

const WorkDetails = ({
  match: {
    params: { id },
  },
}) => {
  const [project, setProject] = useState({})
  useEffect(() => {
    const getProject = async () => {
      const response = await fetchGlobal(ProjectEndpoint(id))
      !response.hasError && setProject(response.data.data)
    }
    getProject()
  }, [])
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Work Details" />
      <Header />
      <Breadcrumb
        image="images/bg/bg-services.jpg"
        title={project.attributes?.name}
        content="Home"
        contentTwo="Work"
      />
      <FinancedProjectsDetailsContainer data={project}  />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

WorkDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default WorkDetails;
