export const BlogsEndpoint = (page) => `blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=3&sort[0]=publishedAt:desc`
export const BlogsFilteredEndpoint = (page, category) => `blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=3&sort[0]=publishedAt:desc&filters[blog_categories][slug][$eq]=${category}`
export const BlogCategoryTitleEndpoint = (category) => `blog-categories?populate=*&filters[slug][$eq]=${category}`
export const BlogsAllEndpoint = 'blogs?populate=*&pagination[-1]&sort[0]=publishedAt:desc'
export const BlogsCategoriesEndpoint = 'blog-categories?populate=*'
export const BlogEndpoint = (id) =>`blogs/${id}?populate=*`
export const ListingsEndpoint = 'listings?populate[propertyPictures][fields][0]=url'
export const ListingEndpoint = (id) => `listings/${id}?populate=*`
export const ServicesEndpoint = 'services?[fields][0]=name&[fields][0]=desc&populate[image][fields][1]=url'
export const ServiceEndpoint = (id) =>`services/${id}?populate[0]=features.feature&populate[1]=benefits.benefit&populate[2]=requirements.requirement&populate[3]=image`
export const ContactEndpoint = 'contact?populate[0]=offices.number&populate[1]=offices.email'
export const DirectorsEndpoint = 'directors?populate=*'
export const DirectorEndpoint = (id) => `directors/${id}?populate=*`
export const staffEndpoint = 'staffs?populate=*'
export const EmployeeEndpoint = (id) => `staffs/${id}?populate=*`
export const HomeCarousel = 'home-carousels?populate[backgroundImage][fields]=url'
export const ValuesEndpoint = 'core-value?populate=*'
export const ScoreBoardEndpoint ='score-board?populate=score'
export const GalleryEndpoint = 'gallery?populate[pictures][fields][0]=url'
export const NavbarEndpoint = 'navbar?populate[0]=services.service'
export const SelectedBlogsEnpoint = 'selected-blogs?populate=blogs.image'
export const ContactFormEndpoint = 'contact-form'
export const ProjectsEndpoint = 'projects?populate[images][fields][0]=url'
export const ProjectEndpoint = (id) => `projects/${id}?populate=*`
export const AboutDeltatrustEndpoint = 'about-us?populate=*'
export const OurCorporateHistoryEndpoint = 'our-corporate-history?populate=*'
export const PrivacyEndpoint = 'terms-privacy/get-privacy'
export const TermsEndpoint = 'terms-privacy/get-terms'
