import React from 'react';
import SectionTitle from '../components/SectionTitles/SectionTitle';
import { Link } from 'react-router-dom';

const CoreValuesHome = () => {
  return (
    <div className="section  bg-white " id='core-values'>
      <SectionTitle
        title="Our Core Values"
        subTitle="At DTMB, our compass is set on customer centricity. This is anchored by our core values, defined by the acronym 'DELTA'."
        id="core-values"
      />
      <div className="container">
      <div className="row justify-content-center">
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1200"
        >
          <div className="single-services">
            <span className="fas fa-bullseye-arrow"></span>

            <h3>Dedication</h3>
            <p>We are committed to quality service delivery.</p>
            <Link to="/about">
              <a className="read-more">
                Learn More <i className="bx bx-plus"></i>
              </a>
            </Link>

            <div className="services-shape"></div>
          </div>
        </div>
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1200"
        >
          <div className="single-services">
            <span className="fas fa-hands-helping"></span>
            <h3>Empathy</h3>
            <p>We deliver service tailored to customers' needs. </p>
            <Link to="/about">
              <a className="read-more">
                Discover More <i className="bx bx-plus"></i>
              </a>
            </Link>

            <div className="services-shape"></div>
          </div>
        </div>

        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1200"
        >
          <div className="single-services">
            <span className="fas fa-crown"></span>
            <h3>Leadership</h3>
            <p>We set the pace. </p>
            <Link to="/about">
              <a className="read-more">
                Read More <i className="bx bx-plus"></i>
              </a>
            </Link>

            <div className="services-shape"></div>
          </div>
        </div>

        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="1200"
        >
          <div className="single-services">
            <span className="fas fa-people-carry"></span>
            <h3>Teamwork</h3>
            <p>Together we achieve more.</p>
            <Link to="/about">
              <a className="read-more">
                Learn More <i className="bx bx-plus"></i>
              </a>
            </Link>

            <div className="services-shape"></div>
          </div>
        </div>
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1200"
        >
          <div className="single-services">
            <span className="fas fa-user-check"></span>
            <h3>Accountability</h3>
            <p>We are driven by integrity. </p>

            <Link to="/about">
              <a className="read-more">
                Read More <i className="bx bx-plus"></i>
              </a>
            </Link>

            <div className="services-shape"></div>
          </div>
        </div>

        <div className='mb-5' style={{ display: 'flex', justifyContent: 'center' }}>
          <Link
            className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4"
            to="/ask-opening-account-form"
          >
            Secure Your Dream Property with Us Today!
          </Link>
          <br/>
        </div>
      </div>

      </div>
      </div>
  );
};

export default CoreValuesHome;
