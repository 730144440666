import React from 'react';
import { Link } from 'react-router-dom';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <img
                  src={`/images/logo/logo.png`}
                  alt="logo"
                  style={{ width: '250px' }}
                />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <Link to={'/'}>08023435688</Link>
                  </p>
                  <p>
                    <Link to={'/'}>customercare@deltatrustmb.com</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="container">
          <div className="row justify-content-start align-items-center">
            <div className="col-12 col-md-6">
              <h4 className="text-white">
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href="https://play.google.com/store/apps/details?id=com.appzonegroup.internetbanking.deltatrust"
                  className="d-flex justify-content-start align-items-center "
                >
                  <div className="d-block"
                    style={{ marginRight: '0.6rem' }}
                  >Download the DTMB Mobile App </div>
                  <i className="fab fa-google-play"></i>
                </a>
              </h4>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <div
                className="d-flex justify-content-end align-items-center "
                style={{
                  gap: '1rem',
                  fontSize: '1.5rem',
                }}
              >
                <a
                  href="https://www.facebook.com/DeltaTrustMortgagebank11"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://instagram.com/deltatrustmortgagebank"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://wa.me/message/22JF63G5OBDTG1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* New Section for Policies and Terms */}
        <div className="row">
          <div className="col">
            <p className="copyright">
              <span>
                <Link to="/privacy-policy" className="footer-link">
                  Privacy Policy
                </Link>
              </span>
              <span style={{ margin: '0 10px' }}>|</span>
              <span>
                <Link to="/terms-of-service" className="footer-link">
                  Terms of Service
                </Link>
              </span>
              <br />
              &copy; 2024 <strong> Delta Trust Mortgage Bank (Licensed by the Central Bank of Nigeria). </strong> All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
