import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFour = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (


    <div className="section section-padding-top about-section-padding-bottom-200">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
            <div className=" mt-0 mb-md-10 mb-10">
              <SectionTitleTwo
                subTitle="Our Mission"
              />

              <div className="row ">
                <div
                  className="col-12 mt-2 mb-6 "
                  style={{
                    fontSize: '1.6rem',
                  }}
                >
                  <p>
                  To provide affordable property ownership schemes through technology-driven, innovative, fast, and cost-effective mortgage solutions, tailored to meet client’s specific needs
                  </p>
                </div>
              </div>
              
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-image-area about-shape-animation right-0 mr-0">
              <div className="about-image js-tilt">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={'images/about/about-1.jpg'} alt="" />
                </Tilt>
              </div>
              <div className="about-image js-tilt">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={'images/about/about-2.jpg'} alt="" />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={'images/shape-animation/video-shape-1.png'}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default AboutFour;
