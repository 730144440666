import React, { useEffect, useState } from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { GalleryEndpoint } from '../APIs';
import { fetchGlobal } from '../utils/apis';

const PhotoGallery = () => {
  const [gallery, setGallery] = useState([])
  useEffect(() => {
    const getGallery = async () => {
      const response = await fetchGlobal(GalleryEndpoint)
      !response.hasError && setGallery(response.data.data.attributes.pictures.data)
    }
    getGallery()
  }, [])
  
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Photo Gallery" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-five.jpg"
        title="Photo Gallery"
        content="Home"
        contentTwo="Photo Gallery"
      />

      <div className="section section-padding-t90-b100">
        <div className="container">
          <div className="text-center mb-10">
            <h1 className="text-primary ">Photo Gallery</h1>
            <p>
              Collection of our photos from various events and activities we
              have been involved in.
            </p>
          </div>
        </div>
        <div className="container">
          <div className='row'>
            {gallery?.map((slide, i) => (
                <div className='col-12 col-md-6 col-xl-4' key={i}>
                  <div className='thumbnail p-3'>
                    <img src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${slide.attributes?.url}`}  alt='.' className='img-fluid' style={{
                      // height: '450px',
                      width: '100%',
                      objecFit: 'cover'
                    }}/>
                    </div>
                </div>
                  
            ))}
          </div>

        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default PhotoGallery;
