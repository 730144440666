import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const Contact = () => {
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Contact" />
      <Header />
      <Breadcrumb
        image="images/bg/bg-contact.jpg"
        title="Connect Today with Delta Trust, Your Preferred Mortgage Partner"
        content="Home"
        contentTwo="Contact Us"
      />
      <ContactInformation />
      <ContactFromContainer />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Contact;
