import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import NowSellingContainer from '../container/NowSelling/NowSellingContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import nowSellingData from '../data/nowselling/nowDetails.json';
import CallToAction from '../container/CallToAction/CallToAction';

const NowSelling = () => {
    return (
        <React.Fragment>
            <SEO title="DeltaTrust || Work" />
            <Header />
            <Breadcrumb 
                //image="images/bg/breadcrumb-services.jpg"
                title="The Mortgage Partner Bringing You Exciting Property Opportunities"
                content="Home"
                contentTwo="Work"
            />
            <NowSellingContainer nowSellingData={nowSellingData} />
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default NowSelling;