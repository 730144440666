import React, {useEffect, useState, useRef} from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import { fetchGlobal } from "../../utils/apis";
import ContactSalesFrom from '../../components/ContactFrom/ContactSalesFrom.jsx';
import { ContactFormEndpoint } from '../../APIs';
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Parallax from 'parallax-js';
import ContactInfoItem from '../../components/ContactInfo/ContactInfoItemTwo';

const ContactSalesFromContainer = () => {
    const [data, setData] = useState({})
    useEffect(() => {
        const getData = async () => {
          const response = await fetchGlobal(ContactFormEndpoint)
          !response.hasError && setData(response.data.data)
        }
        getData()
    }, [])
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding contact-section overlay-two" style={{backgroundImage: `url(/images/bg/contact-form.jpg)`}}>
            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 align-items-center">
                    <div className="col" data-aos="fade-up" data-aos-delay="300">
                        <div className="contact-Information mr-xl-7">
                            <SectionTitleTwo 
                                classOption="color-light"
                                subTitle={data?.attributes?.subTitle}
                                title={data?.attributes?.text}
                            />
              
                            <ContactInfoItem classOption="info-light" title="Head Office" text={data?.attributes?.location} id='1' svg="/images/svg/linea/linea-basic-map.svg"/>
                            <ContactInfoItem classOption="info-light" title="Give Us A Call" text={data?.attributes?.numbers} id='2' svg="/images/svg/linea/linea-basic-message-txt.svg"/>
                            <ContactInfoItem classOption="info-light" title="Help Desk" text={data?.attributes?.emails} id='3' svg="/images/svg/linea/linea-basic-mail-open-text.svg"/>

                        </div>
                    </div>
                    <div className="col row-cols-1 mt-lg-0 mt-md-10 mt-8" data-aos="fade-up" data-aos-delay="300">
                        <div className="contact-form-area">
                            <SectionTitle
                                titleOption="section-title text-center mb-7"
                                headingOption="title fz-28"
                                title="Interested? Our team is just a message away!"
                                subTitle="You can expect to hear from our Sales Team shortly after submitting this form"
                            />

                            <ContactSalesFrom />
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                    <img src={ "images/shape-animation/newsletter-shape.png"} alt="" />
                </span>
            </div>
        </div>
    )
}

export default ContactSalesFromContainer
