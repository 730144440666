import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const WorkItemTwo = ({ data }) => {
  const shortText = (text, maxLength = 60) => {
    if (!text) {
      return ' ';
    }
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + '...';
  };
  return (
    <div className="work">
      <div className="thumbnail">
        <Link className="image" to={`/now-selling-details/${data?.id}`}>
          { data?.attributes?.propertyPictures?.data ?
            <img src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data?.attributes?.propertyPictures?.data[0]?.attributes?.url}`} alt="work" />
            :
            <img src="/images/placeholder/expect-soon.png" alt="work" />
          }
        </Link>
      </div>
      <div className="info">
        <h3 className="title">
          <Link to={`/now-selling-details/${data.id}`}>
            {shortText(data?.attributes?.propertyName)} 
          </Link>
        </h3>

        <h5 className="py-2"
          style={{ color: '#117333' }}
        >
        {data?.attributes?.price}
        </h5>

        <Link
          to={`/now-selling-details/${data?.id}`}
          className="btn btn-primary mt-2 text-white"
        >
          View More Details
        </Link>
      </div>
    </div>
  );
};

WorkItemTwo.propTypes = {
  data: PropTypes.object,
};

export default WorkItemTwo;
