import PropTypes from "prop-types";
import React from "react";
import CounterUpItem from "../../components/CounterUp/CounterUpItem.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { useEffect, useState } from "react";
import { fetchGlobal } from "../../utils/apis";
import { ScoreBoardEndpoint } from "../../APIs";

const Funfact = ({ classOption }) => {
  const [board, setBoard] = useState({});
  const [html, setHtml] = useState("");
  useEffect(() => {
    const getBoard = async () => {
      const response = await fetchGlobal(ScoreBoardEndpoint);
      !response.hasError && setBoard(response.data.data);
      !response.hasError &&
        setHtml(
          `${response.data?.data?.attributes?.points
            ?.split("\n")
            .map(
              (line) =>
                `<li class="item mb-5">
                <div class="text"> <span><i class="icon fa fa-badge-check"></i></span><span>${line.trim()}</span></div>

        </li>`
            )
            .join("")}`
        );
    };
    getBoard();
  }, []);
  return (
    <div
      id="score-board"
      className={`section section-padding-top mb-5 ${classOption}`}
    >
      <SectionTitle
        title="Our Score Board"
        subTitle="Embracing innovation and technology, we are committed to our mission of making property ownership affordable"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
    
            <img style={{width: "600px", height: "650px", objectFit: "cover"}} src="/images/bank-face.png" alt="ScoreBoard" />
          </div>
          <div className="col-lg-6">
            <div className="mx-auto mb-5">
              <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                {board?.attributes?.score?.map((single, key) => {
                  return (
                    <div
                      key={key}
                      className="col mb-6"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <CounterUpItem data={single} key={key} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mx-auto" style={{ color: "#000000" }}>
              <ul className="bank-list">
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Funfact.propTypes = {
  classOption: PropTypes.string,
};
Funfact.defaultProps = {
  classOption: "section section-padding-bottom",
};

export default Funfact;
