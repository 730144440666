import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactSalesFromContainer from '../container/ContactFromContainer/ContactSalesFormContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const SalesContact = () => {
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Sales Contact" />
      <Header />
      <Breadcrumb
        //image="images/bg/breadcrumb-bg-five.jpg"
        title="Our Sales Team is here to help you!"
        content="Home"
        contentTwo="Sales Contact"
      />
      <ContactSalesFromContainer />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default SalesContact;
