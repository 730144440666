import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UploadForm, { uploadDocs } from "./UploadDocs";

const refreshPage = () => {
  window.location.reload();
};

const generateCustomString = (length) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString().substr(2, 2);
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const characters = "0123456789";
  let randomNumber = "";

  for (let i = 0; i < length; i++) {
    randomNumber += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  return "DT" + day + month + year + randomNumber;
};

const OpenFormComponent = () => {
  const [requestStatus, setRequestStatus] = useState({
    status: "",
    acctNumber: "",
    customerID: "",
    errMessage: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // used for disbling the submit button

  const { register, errors, handleSubmit } = useForm({
    mode: "onBlur",
  });

  //read states from previous page if exists
  const location = useLocation();
  const {
    bankVerificationNumber,
    otherNames,
    phoneNumber,
    lastName,
    customerID,
    address,
    email_s,
    dateOfBirth,
  } = location.state || {};

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const {
      accountType,
      firstName,
      middleName,
      lastName,
      bvn,
      email,
      phone,
      address,
      gender,
      nationalIdentityNo,
      dateOfBirth,
    } = data;

    // Create the basic request body
    let requestBody = {
      TransactionTrackingRef: generateCustomString(9),
      AccountOpeningTrackingRef: generateCustomString(11),
      ProductCode: accountType,
      AccountOfficerCode: "36",
      LastName: lastName,
      FirstName: firstName,
      BVN: bvn,
      OtherNames: `${firstName} ${middleName}`,
      FullName: `${firstName} ${middleName} ${lastName} `,
      PhoneNo: phone,
      Gender: gender == "male" ? 0 : 1,
      PlaceOfBirth: "",
      DateOfBirth: dateOfBirth,
      Address: address,
      NationalIdentityNo: nationalIdentityNo,
      AccountInformationSource: 0,
      Email: email,
      NotificationPreference: 0,
      TransactionPermission: 0,
    };

    // If customerID is present, add it to the request body
    if (customerID) {
      requestBody = { ...requestBody, CustomerID: customerID };
    }
    console.log("request body", JSON.stringify(requestBody));
    fetch(`${process.env.REACT_APP_STATIC_IP_CNAME}/api/create-account-quick`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.IsSuccessful) {
          console.log("success. acctNumber: ${response.Message.AccountNumber}");

          setRequestStatus({
            status: "success",
            acctNumber: response.Message.AccountNumber,
            customerID: response.Message.CustomerID,
          });
        } else {
          console.log("failure");
          console.log(response.Message);
          setRequestStatus({
            status: "failure",
            errMessage: response.Message.CreationMessage
              ? response.Message.CreationMessage
              : response.Message,
          });
        }
      })

      .then((response) => console.log(response))
      .catch((err) => {
        console.log("Error occured", err.stack);
        console.error(err);
        setRequestStatus({
          status: "failure",
          errMessage: err.message,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  if (requestStatus.status === "success") {
    return (
      <>
        <div className="section section-padding-t90-b100">
          <div className="col-12 my-5">
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 d-flex justify-content-center align-items-center">
              <div
                className="col mb-6 aos-init aos-animate text-center"
                data-aos="fade-up"
              >
                <div className="contact-info">
                  <div className="icon"></div>

                  <div className="info">
                    <span
                      className="fas fa-thumbs-up"
                      style={{ color: "green" }}
                    ></span>
                    <p className="h4" style={{ color: "green" }}>
                      Congratulations!
                      <br />
                    </p>
                    <br />
                    <h4 className="title text-centre">
                      You have successfully opened your account.{" "}
                    </h4>
                    <br />
                    <span className="info-text text-bold text-dark">
                      Account Number: {requestStatus.acctNumber}{" "}
                    </span>
                    <span className="info-text text-bold text-dark">
                      Customer ID: {requestStatus.customerID}
                    </span>
                    <br />
                    <br />

                    {!customerID && (
                      <>
                        <p
                          className="h4 text-centre"
                          style={{ color: "#FD840C" }}
                        >
                          Important Next Step{" "}
                          <i
                            className="fas fa-comment-exclamation"
                            style={{ marginLeft: "5px" }}
                          ></i>
                        </p>

                        <hr />
                        <p className="info-text text-bold text-dark justify-content-center align-items-center">
                        We require a recent utility bill or a form of identification such as an internal passport or driver's license. 
                        <br/> Please email a copy to us at {" "}
                          <a
                            href="mailto:customercare@deltatrustmb.com"
                            style={{ color: "#FD840C" }}
                          >
                            customercare@deltatrustmb.com.
                          </a>
                          <br />
                          Be sure to reference your account number (
                          {requestStatus.acctNumber} ) in the email.
                        </p>

                        <p> OR upload a document now </p>

                        <UploadForm accountNumber={requestStatus.acctNumber} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  } else if (requestStatus.status === "failure") {
    return (
      <>
        <div className="section section-padding-t90-b100 mb-20">
          <p className="text-center h3" style={{ color: "red" }}>
            An Error occured
            <br />"{requestStatus.errMessage}"
            <br />
          </p>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              onClick={refreshPage}
              className="read-more"
              style={{ marginRight: "20px" }}
            >
              <span className="fas fa-redo fa-2x"> Try Again</span>
            </div>

            <Link to="/contact" className="read-more">
              <span className="fas fa-phone-square fa-2x ">
                {" "}
                Contact support{" "}
              </span>
            </Link>
          </div>

          <br />
          <br />
        </div>
      </>
    );
  }

  return (
    <div className="section section-padding-t90-b100">
      <div className="container">
        <div className="text-center mb-10">
          <h1 className="text-primary">Open Your Account</h1>
          <div className="info text-center">
            Please fill the form below to open an account with Delta Trust{" "}
          </div>
        </div>
        <div className="title pb-5 text-left">
          <h3 data-aos="fade-up">
            Step 2 of 2: Enter your personal details <br />
          </h3>

          <hr />
        </div>
        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-n6">
              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>Account Type *</label>
                <select
                  className="form-control"
                  name="accountType"
                  ref={register({
                    required: "Account Type is required",
                  })}
                >
                  <option value="101">Popular Savings</option>
                  <option value="102">Children Savings</option>
                  <option value="106">RSA Savings</option>
                  <option value="105">Target Savings</option>
                  <option value="103">Home Purchase</option>
                  <option value="104">DT Rent</option>
                  <option value="201">Individual Current</option>
                  <option value="203">Corporate Current</option>
                  <option value="204">Current Project</option>
                  <option value="205">Promo Individual</option>
                </select>
                {errors.accountType && <p>{errors.accountType.message}</p>}
              </div>

              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>First Name *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name *"
                  name="firstName"
                  defaultValue={otherNames}
                  readOnly={!!otherNames}
                  ref={register({
                    required: "First Name is required",
                  })}
                />
                {errors.firstName && <p>{errors.firstName.message}</p>}
              </div>

              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>Middle Name </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Middle Name"
                  name="middleName"
                  ref={register({
                    //required: 'Middle Name is required',
                  })}
                />
                {errors.middleName && <p>{errors.middleName.message}</p>}
              </div>
              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>Last Name *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name *"
                  defaultValue={lastName}
                  readOnly={!!lastName}
                  name="lastName"
                  ref={register({
                    required: "Last Name is required",
                  })}
                />
                {errors.lastName && <p>{errors.lastName.message}</p>}
              </div>
              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>BVN (Bank Verfication Number) *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="BVN (Bank Verfication Number) *"
                  name="bvn"
                  defaultValue={bankVerificationNumber}
                  readOnly={!!bankVerificationNumber}
                  ref={register({
                    required: "bvn is required",
                    pattern: {
                      // value: /^[0-9]$/i,
                      // value: /^[0-9]{11}$/i,
                      message: "invalid bvn address",
                    },
                  })}
                />
                {errors.bvn && <p>{errors.bvn.message}</p>}
              </div>
              <div className="col-md-6 col-md-6 col-12 mb-6">
                <label>Email *</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email *"
                  name="email"
                  defaultValue={email_s}
                  readOnly={!!email_s}
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
              <div className="col-md-6 col-12 mb-6">
                <label>Phone Number *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number *"
                  name="phone"
                  defaultValue={phoneNumber}
                  readOnly={!!phoneNumber}
                  ref={register({
                    required: "Phone Number is required",
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "invalid Phone Number address",
                    },
                  })}
                />
                {errors.phone && <p>{errors.phone.message}</p>}
              </div>
              <div className="col-md-6 col-12 mb-6">
                <label>Date of Birth *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Date of Birth *"
                  defaultValue={dateOfBirth}
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.focus();
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.blur();
                  }}
                  name="dateOfBirth"
                  ref={register({
                    required: "Date of Birth is required",
                  })}
                />
                {errors.dateOfBirth && <p>{errors.dateOfBirth.message}</p>}
              </div>
              <div className="col-md-6 col-12 mb-6">
                <label>Address *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address *"
                  name="address"
                  defaultValue={address}
                  readOnly={!!address}
                  ref={register({
                    required: "Address is required",
                    pattern: {
                      value: /^[A-Za-z0-9'\.\-\s\,]/i,
                      message: "invalid Phone Number address",
                    },
                  })}
                />
                {errors.address && <p>{errors.address.message}</p>}
              </div>
              <div className="col-md-6 col-12 mb-6">
                <label>National ID (NIN): </label>
                <input
                  className="form-control"
                  placeholder="Ex: 1234567890"
                  name="nationalIdentityNo"
                  ref={register({
                    // required: 'National ID Number is required',
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "invalid ID Number",
                    },
                  })}
                />
                {errors.nationalIdentityNo && (
                  <p>{errors.nationalIdentityNo.message}</p>
                )}
              </div>
              <div className="col-md-6 col-12 mb-6">
                <label>Gender *</label>
                <select
                  className="form-control"
                  name="gender"
                  ref={register({ required: "Gender is required" })}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="col-12 text-start mb-6 btn text-primary">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ backgroundColor: "#FD840C", color: "white" }}
                >
                  {isSubmitting ? (
                    <>
                      <img
                        src="images/6-dots-rotate.svg"
                        alt="Creating your DTMB Account..."
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                      Please Wait...
                    </>
                  ) : (
                    "Open Account Now"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OpenFormComponent;
