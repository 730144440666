import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle.jsx";
import SingleTeam from "../../components/Team/SingleTeam.jsx";
import { useEffect, useState } from "react";
import { fetchGlobal } from "../../utils/apis.js";
import { DirectorsEndpoint, staffEndpoint } from "../../APIs.js";

const compareFunction = (a, b) => {
  if (a.attributes.priority < b.attributes.priority) {
    return -1;
  }
  if (a.attributes.priority > b.attributes.priority) {
    return 1;
  }
  return 0;
};

const Team = () => {
  const [directors, setDirectors] = useState([])
  const [staff, setStaff] = useState([])
  useEffect(() => {
    const getData = async () => {
      try {
        let response = await fetchGlobal(DirectorsEndpoint)
        if (response.hasError) {
          throw new Error('Error Fetching Data');
        }

        let directorsArray = response.data?.data || [];
        directorsArray.sort(compareFunction)
        setDirectors(directorsArray);

        response = await fetchGlobal(staffEndpoint)
        if (response.hasError) {
          throw new Error('Error Fetching Data');
        }

        let staffArray = response.data?.data || [];
        staffArray.sort(compareFunction)
        setStaff(staffArray)

      } catch (err) {
        console.log('error fetching now')
      }
    }
    getData()
  }, [])

  return (
    <>
      <div
        id="board-of-directors"
        className="section mt-n2  bg-primary-blue"
      >
        <div className="container">
          <SectionTitle
            titleOption="text-center mb-12"
            title="Board Of Directors"
            subTitle="Meet the Leaders at the Helm of Delta Trust Mortgage Bank."
          />
          <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="row row-cols-lg-2 row-cols-sm-2 row-cols-1 mb-n6">
            {directors?.map((single, key) => {
                return (
                  <div
                    key={key}
                    className="col-12 col-md-6 col-lg-6 mb-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <SingleTeam type="directors" data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      </div>
      </div>
      <div
        id="management-team"
        className="section section-padding bg-primary-blue"
      >
        <div className="container">
          <SectionTitle
            titleOption="text-center mb-12"
            title="Management Staff"
            subTitle="Meet the Management Team Behind Delta Trust Mortgage Bank's Success"

          />
             <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="row row-cols-lg-2 row-cols-sm-2 row-cols-1 mb-n6">
            {staff?.map((single, key) => {
                return (
                  <div
                    key={key}
                    className="col-12 col-md-6 col-lg-6 mb-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <SingleTeam type="staff" data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Team;
