import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import SectionTitle from "../components/SectionTitles/SectionTitle.jsx";
import AboutFour from '../container/About/AboutFour';
import AboutFive from '../container/About/AboutFive';
import AboutSix from '../container/About/AboutSix';
import Team from '../container/Team/Team';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import OurCorporateHistory from '../container/About/OurCorporateHistory';
import AboutDeltatrust from '../container/About/AboutDeltatrust';
import { useEffect } from 'react';

const AboutUs = () => {
  useEffect(() => {
    setTimeout(() => {
      const href = window.location.href.substring(
        window.location.href.lastIndexOf('#') + 1
      );
      const element = document.getElementById(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500)
  }, []);

  return (
    <React.Fragment>
      <SEO title="DeltaTrust || About" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg.jpg"
        title="Discover Delta Trust, Your Reliable Mortgage Banking Partner"
        content="Home"
        contentTwo="About Us"
      />
      <AboutDeltatrust />

      <div
        id="statement-of-purpose"
        className="section mb-20 bg-primary-blue"
      >
        <div className="container">
          <SectionTitle
            titleOption="text-center mb-8"
            title="Statement of Purpose"
          
          />
      <AboutFour />
    
      <AboutFive />
      </div>
      </div>
      <AboutSix />
    
      <OurCorporateHistory />
    
      <Team />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AboutUs;


