import React from 'react'
import SEO from '../components/SEO';
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import BlogDetailsContainer from '../container/BlogGrid/BlogDetailsContainer';
import CallToAction from '../container/CallToAction/CallToAction';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { useEffect, useState } from 'react';
import { fetchGlobal } from '../utils/apis';
import { BlogEndpoint } from '../APIs';

const BlogDetails = ({match: {params: {id}}}) => {
    const [blog, setBLog] = useState({})
    useEffect(() => {
        const getBlog = async () => {
          const response = await fetchGlobal(BlogEndpoint(id))
          !response.hasError && setBLog(response.data.data)
        }
        getBlog()
      }, [window.location.href])
    return (
        <React.Fragment>
            <SEO title="DeltaTrust || Blog Details" />
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-four.jpg"
                title={blog?.attributes?.title}
                content="Home"
                contentTwo="Blogs & News"
            />
            { blog?.attributes && <BlogDetailsContainer data={blog} />}
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

BlogDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([    
                PropTypes.string,
                PropTypes.number
            ])
        })
    })
}

export default BlogDetails;
