import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const ContactFrom = () => {
  const { register, errors } = useForm({
    mode: 'onBlur',
  });
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_CONTACT_ID || 'no-endpoint' );

  if (state.succeeded) {
    return (
      <p className="text-center h3 text-primary">
        Your Data has been submitted! <br /> Thanks for contacting us
      </p>
    );
  }
  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form onSubmit={handleSubmit}>
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input type="text" required placeholder="Your Name *" name="name" />
            <ValidationError prefix="text" field="name" errors={state.errors} />
          </div>

          <div className="col-md-6 col-12 mb-6">
            <input
              type="email"
              required
              placeholder="Email *"
              name="email"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>


          <div className="col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Phone Number"
              name="phone"
            />
          
          </div>

          <div className="col-12 mb-6">
            <input
              type="text"
              required
              placeholder="Subject *"
              name="subject"
            />
            <ValidationError
              prefix="text"
              field="subject"
              errors={state.errors}
            />
          </div>

          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message"
              required
            ></textarea>
            <ValidationError
              prefix="text"
              field="message"
              errors={state.errors}
            />
          </div>
          <div className="col-12 text-center mb-6">
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary w-100"
              disabled={state.submitting}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;
