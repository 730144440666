import { useEffect, useRef } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { Link } from "react-router-dom";
import Parallax from "parallax-js";

const CallToAction = () => {
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div
      className="section section-bg-image section-padding-t110-b120 newsletter-section overlay-two"
      style={{ backgroundImage: `url(./images/bg/newsletter.jpg)` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 m-auto">
            <div className="cta-content text-center">
              <SectionTitle
                titleOption="color-light text-center"
                title="Discover how we can collaborate for success."
                subTitle="Our team of mortgage professionals is dedicated to providing top-notch service and takes pride in helping our clients meet their investment goals. We are committed to exceeding expectations and continually improving our own capabilities. Whether it's managing your finances, securing a loan, or investing in your future, our team is passionate about assisting you in achieving your objectives."
              />
              <Link
                to={"/contact"}
                className="btn btn-primary btn-hover-secondary mt-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="shape shape-1" id="scene" ref={sceneEl}>
        <span data-depth="1">
          <img src={"/images/shape-animation/contact-shape.png"} alt="" />
        </span>
      </div>
    </div>
  );
};

export default CallToAction;
