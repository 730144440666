import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import FinancedProjectsContainer from "../container/FinancedProjects/FinancedProjectsContainer";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useEffect, useState } from "react";
import { ProjectsEndpoint } from "../APIs";
import { fetchGlobal } from "../utils/apis";

const FinancedProjects = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getProjects = async () => {
      const response = await fetchGlobal(ProjectsEndpoint);
      !response.hasError && setProjects(response.data.data);
    };
    getProjects();
  }, []);
  return (
    <React.Fragment>
      <SEO title="DeltaTrust || Work" />
      <Header />
      <Breadcrumb
        image="images/bg/services.jpg"
        title="Delta Trust's Portfolio: A Testament to Successful Financing"
        content="Home"
        contentTwo="Financial Projects"
      />
      <FinancedProjectsContainer financedProjectsData={projects} />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default FinancedProjects;
