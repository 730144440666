import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const BlogDetails = ({ data }) => {
    return (
        <div className="blog-3 blog-details col" data-aos="fade-up">
            <div className="thumbnail">
                {data.attributes.image.data ?
                    <Link to={`/blog-details/${data.id}`} className="image"><img src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${data.attributes.image.data.attributes.url}`} alt="Blog Image" /></Link>
                    :
                    <Link to={`/blog-details/${data.id}`} className="image"><img src="/images/placeholder/expect-soon.png" alt="Blog Image" /></Link>
                }
            </div>
            <div className="info">
                <h3 className="title">{data.attributes.title}</h3>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={data.attributes.content}
                />
                <ul className="meta mb-0 mt-12">
                    {/* <li><i className="fal fa-pencil-alt"></i>{data.attributes?.author?.data && data.attributes.author.data.attributes.name}</li> */}
                    {data?.attributes?.publishedAt && (
                        <li><i className="far fa-calendar"></i>{(new Date(data.attributes.publishedAt)).toLocaleString()}</li>
                    )}
                    {/* <li><i className="fas fa-tags"></i>{data.attributes.category}</li> */}
                    {/* <li className="media"><Link to={ "/"}><i className="fas fa-share-alt"></i>Share this post</Link>
                        <div className="list">
                            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                            <a href="https://www.tumblr.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-tumblr-square"></i></a>
                        </div>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

BlogDetails.propTypes = {
    data: PropTypes.object
};

export default BlogDetails
